import moment from 'moment';
import React from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import SplitButton from 'react-bootstrap/SplitButton';
import '../../listTable.css';

export default function ClientUserInvitationCard(props: {
  key: number;
  clientUserInvitationID: number;
  firstname: string;
  lastname: string;
  emailAddress: string;
  clientCode: string;
  invitationCode: string;
  acceptedSignalUserID: string;
  acceptedOnUtc: string;
  active: boolean;
  modifiedOnUtc: string;
  handleDisableEnableClick: (event: React.MouseEvent) => void;
  handleResendClick: (event: React.MouseEvent) => void;
}): JSX.Element {
  return (
    <>
      <tr className="list-table-row">
        <td className="list-table-col list-table-col-text">
          {props.acceptedSignalUserID ? (
            <Badge pill bg="success">
              Activated
            </Badge>
          ) : props.active ? (
            <Badge pill bg="warning">
              Invited
            </Badge>
          ) : (
            <Badge pill bg="danger">
              Disabled
            </Badge>
          )}
        </td>
        <td className="list-table-col list-table-col-text">{props.firstname}</td>
        <td className="list-table-col list-table-col-text">{props.lastname}</td>
        <td className="list-table-col list-table-col-text">{props.emailAddress}</td>
        <td className="list-table-col list-table-col-text">
          {!props.acceptedSignalUserID &&
            (props.active ? (
              <SplitButton
                size={'sm'}
                title={props.active ? 'Disable' : 'Enable'}
                onClick={props.handleDisableEnableClick}
                drop={'down'}
                align={'end'}
                variant={'danger'}
                id={props.clientUserInvitationID.toString()}
              >
                {props.active && (
                  <>
                    <Dropdown.Item onClick={props.handleResendClick}>Resend Email</Dropdown.Item>
                  </>
                )}
              </SplitButton>
            ) : (
              <Button
                id={props.clientUserInvitationID.toString()}
                variant="success"
                size="sm"
                onClick={props.handleDisableEnableClick}
              >
                Enable
              </Button>
            ))}
        </td>
        <td className="list-table-col list-table-col-text">{props.clientCode}</td>
        <td className="list-table-col list-table-col-text">{props.invitationCode}</td>
        <td className="list-table-col list-table-col-text">
          {props.acceptedOnUtc != null ? moment(props.acceptedOnUtc).format('MM/DD/yyyy HH:mm') : ''}
        </td>
      </tr>
    </>
  );
}
