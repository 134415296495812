import api from '../../common/api';
import { postData } from '../../models/Helpers/FetchHelper';
import { getApiUrlOrDefault } from './shared';

export function createAuthenticationApiClient(options?: { baseUrl?: string; apiUrl?: string }) {
  const baseUrl = `${api.baseUrl}/`;
  const apiUrl = getApiUrlOrDefault(options?.apiUrl, baseUrl);
  const client = Object.freeze({
    activateAccount: async function (request: { signalUserId: string; verificationCode: string; password: string }) {
      const activateAccountResponse = await postData(`${apiUrl}authentication/activateAccount`, request);

      var response = (await activateAccountResponse.json()) as {
        isActivated: boolean;
        errorMessages: string[];
      };
      return response;
    },
    createAccount: async function (request: { email: string; firstname: string; lastname: string }) {
      const createAccountResponse = await postData(`${apiUrl}authentication/createAccount`, request);

      // Potentially returns 400.
      var response = {
        isCreated: createAccountResponse.ok,
        errorMessages: [] as string[],
      };
      if (createAccountResponse.status === 400) {
        response.errorMessages = (await createAccountResponse.json()) as string[];
      }
      return response;
    },
    forgotPassword: async function (emailAddress: string) {
      interface ForgotPasswordRequest {
        emailAddress: string;
      }

      const forgotPasswordResponse = await postData(`${apiUrl}authentication/forgotPassword`, {
        emailAddress: emailAddress,
      } as ForgotPasswordRequest);

      if (!forgotPasswordResponse.ok) {
        throw new Error('Server responded with error');
      }

      return true;
    },
    resetPassword: async function (data: { verificationToken: string; emailAddress: string; password: string }) {
      try {
        const forgotPasswordResponse = await postData(`${apiUrl}authentication/resetPassword`, data);

        var response = (await forgotPasswordResponse.json()) as {
          passwordReset: boolean;
          errorMessages: string[];
        };
        return response;
      } catch {
        throw new Error('Server responded with error');
      }
    },
  });
  return client;
}

export type AuthenticationApiClient = ReturnType<typeof createAuthenticationApiClient>;
