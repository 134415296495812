import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCirclePlus } from '@imagetrend/fontawesome-pro/pro-solid-svg-icons';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useMatch, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../common/appState/appStateHooks';
import { RightNavItems, selectRightNavActions } from '../../common/appState/rightNavActionsSlice';
import routes from '../../common/routesDefinitions';
import { initialIncident } from '../callTriage/incident/incidentDetails.types';
import SignalModal from '../common/signalModal';
import BaseSideNav from './baseNav';

const filteredIncident = (arr: [string, unknown][]): [string, unknown][] => {
  return arr.filter((x) => !['eventID', 'createdBy', 'createdOnUtc', 'startBy', 'startOnUtc'].includes(x[0]));
};

const RightSideNav = () => {
  const navActions = useAppSelector(selectRightNavActions);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const isCallTriage = !!useMatch(`${routes.callTriageIncident}/*`);

  const createNewIncident = () => {
    sessionStorage.setItem('callTriageIncident', JSON.stringify(initialIncident));
    navigate(`${routes.callTriageIncident}`, { state: {} });
  };

  const createNewClicked = () => {
    const savedData = sessionStorage.getItem('callTriageIncident');
    // We're removing several items that are not comparators, since they're automatically set.
    if (
      !savedData ||
      filteredIncident(Object.entries(JSON.parse(savedData))).toString() !==
        filteredIncident(Object.entries(initialIncident)).toString()
    ) {
      setShowModal(true);
      return;
    }
    createNewIncident();
  };

  return (
    <>
      {navActions.length > 0 && (
        <BaseSideNav>
          {navActions.map((action, index) => {
            switch (action) {
              case RightNavItems.addCallTriageIncidentButton:
                return (
                  <div key={`rightHandNav-${index}`}>
                    <Button onClick={createNewClicked}>
                      New Incident <FontAwesomeIcon icon={faFileCirclePlus} />
                    </Button>
                    <SignalModal
                      id={'create-new-incident-modal'}
                      show={showModal}
                      headerTitle={'Unsaved Information'}
                      confirmText={'Create New'}
                      negateText={isCallTriage ? 'Continue Editing' : 'Load Saved'}
                      toggleShow={() => setShowModal(false)}
                      confirm={() => {
                        createNewIncident();
                      }}
                      negate={() => {
                        if (!isCallTriage) {
                          navigate(`${routes.callTriageIncident}`);
                        }
                      }}
                    >
                      <p>You have unsaved incident information.</p>
                    </SignalModal>
                  </div>
                );
              default:
                return <></>;
            }
          })}
        </BaseSideNav>
      )}
    </>
  );
};

export default RightSideNav;
