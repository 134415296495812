import { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import { Outlet } from 'react-router-dom';
import api from '../../common/api';

const AuthRoute = () => {
  const [renderRoute, setRenderRoute] = useState(false);

  useEffect(() => {
    const authedUser = sessionStorage.getItem('user');

    if (!authedUser) {
      if (renderRoute !== false) {
        setRenderRoute(false);
      }
    } else {
      if (renderRoute !== true) {
        setRenderRoute(true);
      }
    }
  }, [renderRoute, setRenderRoute]);

  useEffect(() => {
    if (!renderRoute || !api.walkMeUrl) return;

    const walkme = document.createElement('script');
    walkme.type = 'text/javascript';
    walkme.async = true;
    walkme.src = api.walkMeUrl;
    const s = document.getElementsByTagName('script')[0];
    s.parentNode!.insertBefore(walkme, s);
    (window as any)._walkmeConfig = { smartLoad: true };

    return () => {
      if (s.parentNode?.contains(walkme)) {
        s.parentNode.removeChild(walkme);
      }
    };
  }, [renderRoute]);

  if (renderRoute) {
    return <Outlet />;
  }

  return (
    <Col className="col-md-4 col-sm-12 offset-md-4">
      <Alert className="text-center" variant="warning">
        You do not have permission to view this
      </Alert>
    </Col>
  );
};

export default AuthRoute;
