import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link, useNavigate } from 'react-router-dom';
import routes from '../../common/routesDefinitions';
import Logo from '../logo/logo';
import { useApiClient } from '../useApiClient/useApiClient';

export default function ForgotPassword() {
  const navigate = useNavigate();
  const apiClient = useApiClient();

  const [emailAddress, setEmailAddress] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleEmailChange = (event: any) => {
    setEmailAddress(event.target.value);
  };

  const handleFormSubmit = (event: any) => {
    event.preventDefault();

    const forgotPassword = async () => {
      try {
        await apiClient.Authentication.forgotPassword(emailAddress);
        navigate(routes.forgot2, {
          state: {
            emailAddress: emailAddress,
          },
        });
      } catch {
        setErrorMessage('Server did not respond.  Please contact support or try again.');
      }
    };
    forgotPassword();
  };

  return (
    <>
      <Row className="mx-0">
        <Col className="textRight">
          <Logo />
        </Col>
      </Row>
      <Container>
        <Row className="mx-0 justify-content-center">
          <Col xl={5} lg={12} md={9}>
            <div className="form-bg o-hidden form-border shadow-lg my-5">
              <Row className="mx-0">
                <Col lg={12}>
                  <h4 className="forgotHeader" id="forgotPanelTop">
                    Forgot your password?
                  </h4>
                  <p className="forgotHeader mx-5">
                    Type your account email address and we'll send you a code to reset it.
                  </p>
                  <div className="forgotContent">
                    <Form onSubmit={(e) => handleFormSubmit(e)}>
                      <Alert dismissible onClose={() => setErrorMessage('')} show={!!errorMessage} variant="danger">
                        {errorMessage}
                      </Alert>
                      <p className="form-title">Email Address</p>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          name="emailAddress"
                          onChange={handleEmailChange}
                          value={emailAddress}
                          required
                        />
                      </Form.Group>
                      <Row className="my-1">
                        <Col>
                          <Link to="/" className="small">
                            Back to Login
                          </Link>
                        </Col>
                      </Row>
                      <div className="row mb-4 mt-5 mx-1">
                        <Button type="submit" name="login" variant="primary" className="col-12">
                          SEND CODE
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
