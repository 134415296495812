import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Select from 'react-select';
import { Responder } from '../../models/Responder';
import {
  ArrivedIcon,
  CancelIcon,
  ClosedIcon,
  RespondingIcon,
  UnavailableIcon,
} from '../faIconImports/fontAwesomeIcons';
import { useApiClient } from '../useApiClient/useApiClient';
import { useInterval } from '../useInterval/useInterval';
import ResponderCard from './responderCard';

const ResponderMode = () => {
  const apiClient = useApiClient();
  const [inputList, setInputList] = useState([
    {
      input: '',
      key: '',
      response: '',
      inputRank: null,
    },
  ]);
  const [showEditPersonalResponseModal, setShowEditPersonalResponseModal] = React.useState(false);
  const [statusUpdate, setStatusUpdate] = React.useState(0);
  const [showAddRespondersModal, setShowAddRespondersModal] = React.useState(false);
  const clientListObject = JSON.parse(sessionStorage.clientList);
  const [responders, setResponders] = useState<Responder[]>([]);
  const [responderNames, setResponderNames] = React.useState([{ value: 'name', label: 'name', key: 'name' }]);
  const arrivedStaffScene = responders.filter((staff) => staff.status === 'Arrived On-Scene');
  const respondingStaffScene = responders.filter((staff) => staff.status === 'Responding To Scene');
  // const cancelingStaff = responders.filter((staff) => staff.status === 'Canceling');
  // const clearStaff = responders.filter((staff) => staff.status === 'Clear');
  const activeStaff = responders.filter(
    (staff) =>
      staff.status === 'Arrived At Station' ||
      staff.status === 'Responding To Station' ||
      staff.status === 'Arrived On-Scene' ||
      staff.status === 'Responding To Scene'
  );
  const inactiveStaff = responders.filter((staff) => staff.status === 'Canceling' || staff.status === 'Clear');
  const unavailableStaff = responders.filter((staff) => staff.status === 'Unavailable');
  const [responderHistory, setResponderHistory] = React.useState([
    {
      signalUserID: '',
      createdOnUtc: '',
      dispatchResponderHistoryID: '',
      dispatchResponderID: '',
      firstname: '',
      incidentID: '',
      lastname: '',
      status: '',
      statusTypeID: '',
    },
  ]);
  const userObject = {
    signalUserID: clientListObject[0].signalUserID,
    firstName: clientListObject[0].firstname,
    lastName: clientListObject[0].lastname,
  };
  const responseOptions = [
    {
      value: 4,
      label: 'Arrived',
    },
    {
      value: 2,
      label: 'Responding',
    },
    {
      value: 6,
      label: 'Canceling',
    },
    {
      value: 7,
      label: 'Clear',
    },
    {
      value: 1,
      label: 'Unavailable',
    },
  ];
  const activeTitle = (
    <div>
      Active <Badge bg="secondary">{activeStaff.length}</Badge>
    </div>
  );
  const inactiveTitle = (
    <div>
      Inactive <Badge bg="secondary">{inactiveStaff.length}</Badge>
    </div>
  );
  const unavailableTitle = (
    <div>
      Unavailable <Badge bg="secondary">{unavailableStaff.length}</Badge>
    </div>
  );

  const handleAddResponderModalClose = () => setShowAddRespondersModal(false);
  const handleAddResponderModalShow = () => setShowAddRespondersModal(true);
  const handleEditPersonalResponseModalClose = () => {
    setShowEditPersonalResponseModal(false);
    setStatusUpdate(0);
    setArrivedActive(false);
    setClosedActive(false);
    setCancelActive(false);
    setUnavailableActive(false);
    setResponseActive(false);
  };
  const handleListAdd = () => {
    setInputList([
      ...inputList,
      {
        input: '',
        key: '',
        response: '',
        inputRank: null,
      },
    ]);
  };
  const handleInputChange = (event: any, index: any) => {
    if (!event.key) {
      const response = event.value;
      const newInputList = [...inputList];
      newInputList[index].response = response;
      newInputList[index].inputRank = index + 1;
      setInputList(newInputList);
    } else {
      const value = event.value;
      const key = event.key;
      const newInputList = [...inputList];
      newInputList[index].input = value;
      newInputList[index].key = key;
      newInputList[index].inputRank = index + 1;
      setInputList(newInputList);
    }
  };
  const handleRemoveItem = (index: any) => {
    const newList = [...inputList];
    newList.splice(index, 1);
    setInputList(newList);
  };

  const updateResponders = useCallback(() => {
    const retrieveResponders = async () => {
      try {
        const queryResult = await apiClient.getResponders({});
        sessionStorage.setItem('responderHistory', JSON.stringify(queryResult));
        setResponders(queryResult.sort((a: any, b: any) => a.firstname.localeCompare(b.firstname)));
        let names = [];
        for (let i = 0; i < queryResult.length; i++) {
          names.push({
            value: queryResult[i].firstname + ' ' + queryResult[i].lastname,
            label: queryResult[i].firstname + ' ' + queryResult[i].lastname,
            key: queryResult[i].signalUserID,
          });
        }
        setResponderNames(names);
      } catch (err) {
        console.error('Failed to fetch responder data', err);
      }
    };

    retrieveResponders();
  }, [apiClient]);

  useEffect(() => {
    updateResponders();
  }, [apiClient, updateResponders]);
  const intervalSeconds = 1;
  useInterval(updateResponders, intervalSeconds * 1000);

  const handleBatchResponderChange = async () => {
    try {
      for (let i = 0; i < inputList.length; i++) {
        await apiClient.changeResponderStatus('', inputList[i].key, inputList[i].response);
      }
    } catch (err) {
      console.error('Failed to alter responder data', err);
    }
  };
  const handleEditPersonalResponseModalShow = () => {
    getPersonalResponderHistory();
    setShowEditPersonalResponseModal(true);
  };
  const getPersonalResponderHistory = async () => {
    try {
      for (let i = 0; i < inputList.length; i++) {
        const queryResult = await apiClient.getResponderHistoryByID('');
        if (!queryResult[0].status) {
          setResponderHistory([
            {
              signalUserID: '',
              createdOnUtc: '',
              dispatchResponderHistoryID: '',
              dispatchResponderID: '',
              firstname: 'Responder history is not available for the last 24 hours',
              incidentID: '',
              lastname: '',
              status: '',
              statusTypeID: '',
            },
          ]);
        }
        let reversed = queryResult.reverse();
        setResponderHistory(reversed);
      }
    } catch (err) {
      console.error('Failed to fetch responder history', err);
    }
  };

  const handleRespondingResponse = () => {
    setStatusUpdate(2);
    setArrivedActive(false);
    setClosedActive(false);
    setCancelActive(false);
    setUnavailableActive(false);
    setResponseActive(true);
  };
  const handleArrivedResponse = () => {
    setStatusUpdate(4);
    setArrivedActive(true);
    setClosedActive(false);
    setCancelActive(false);
    setUnavailableActive(false);
    setResponseActive(false);
  };
  const handleClosedResponse = () => {
    setStatusUpdate(7);
    setArrivedActive(false);
    setClosedActive(true);
    setCancelActive(false);
    setUnavailableActive(false);
    setResponseActive(false);
  };
  const handleCancelResponse = () => {
    setStatusUpdate(6);
    setArrivedActive(false);
    setClosedActive(false);
    setCancelActive(true);
    setUnavailableActive(false);
    setResponseActive(false);
  };
  const handleUnavailableResponse = () => {
    setStatusUpdate(1);
    setArrivedActive(false);
    setClosedActive(false);
    setCancelActive(false);
    setUnavailableActive(true);
    setResponseActive(false);
  };

  const [responseActive, setResponseActive] = React.useState(false);
  const [arrivedActive, setArrivedActive] = React.useState(false);
  const [closedActive, setClosedActive] = React.useState(false);
  const [cancelActive, setCancelActive] = React.useState(false);
  const [unavailableActive, setUnavailableActive] = React.useState(false);

  const handlePersonalResponseChange = async () => {
    try {
      const queryResult = await apiClient.changeResponderStatus('', userObject.signalUserID, statusUpdate);
      console.log(queryResult);
      getPersonalResponderHistory();
      setStatusUpdate(0);
      setArrivedActive(false);
      setClosedActive(false);
      setCancelActive(false);
      setUnavailableActive(false);
      setResponseActive(false);
    } catch (err) {
      console.error('Failed to update response data', err);
    }
  };

  const addRespondersModal = (
    <Modal size="lg" show={showAddRespondersModal} onHide={handleAddResponderModalClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">Add New Responder(s)</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {inputList.map((input, index) => (
          <>
            <Row className="mb-2">
              <Col className="col">
                <Select options={responderNames} key={index} onChange={(event) => handleInputChange(event, index)} />
              </Col>
              <Col className="col">
                <Select options={responseOptions} key={index} onChange={(event) => handleInputChange(event, index)} />
              </Col>
              <Col className="col-1">
                <Button variant="danger" onClick={() => handleRemoveItem(index)}>
                  X
                </Button>
              </Col>
            </Row>
          </>
        ))}
        <Button variant="secondary" onClick={handleListAdd}>
          Add Another
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleAddResponderModalClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleBatchResponderChange}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const editPersonalResponseModal = (
    <Modal size="lg" show={showEditPersonalResponseModal} onHide={handleEditPersonalResponseModalClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: 'bold' }}>Change your Response Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <p>Responder Name</p>
          </Col>
          <Col>
            <p>Current Status</p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <p style={{ color: 'gray' }}>
              {userObject.firstName} {userObject.lastName}
            </p>
          </Col>
          <Col>
            <p style={{ color: 'gray' }}>{responderHistory[0].status}</p>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Button
              onClick={handleRespondingResponse}
              active={responseActive}
              variant="outline-secondary"
              className="addResponderModalButton"
            >
              <Row>
                <Col>
                  <RespondingIcon size={'2x'} />
                </Col>
              </Row>
              <Row>
                <p>Responding</p>
              </Row>
            </Button>
          </Col>
          <Col>
            <Button
              onClick={handleArrivedResponse}
              active={arrivedActive}
              variant="outline-secondary"
              className="addResponderModalButton"
            >
              <Row>
                <Col>
                  <ArrivedIcon size={'2x'} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>Arrived</p>
                </Col>
              </Row>
            </Button>
          </Col>
          <Col>
            <Button
              onClick={handleClosedResponse}
              active={closedActive}
              variant="outline-secondary"
              className="addResponderModalButton"
            >
              <Row>
                <Col>
                  <ClosedIcon size={'2x'} />
                </Col>
              </Row>
              <Row>
                <p>Clear/Closed</p>
              </Row>
            </Button>
          </Col>
          <Col>
            <Button
              onClick={handleCancelResponse}
              active={cancelActive}
              variant="outline-secondary"
              className="addResponderModalButton"
            >
              <Row>
                <Col>
                  <CancelIcon size={'2x'} />
                </Col>
              </Row>
              <Row>
                <p>Cancel</p>
              </Row>
            </Button>
          </Col>
          <Col>
            <Button
              onClick={handleUnavailableResponse}
              active={unavailableActive}
              variant="outline-secondary"
              className="addResponderModalButton"
            >
              <Row>
                <Col>
                  <UnavailableIcon size={'2x'} />
                </Col>
              </Row>
              <Row>
                <p>Unavailable</p>
              </Row>
            </Button>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>Responder's History</Col>
        </Row>
        {responderHistory.map((data) => (
          <>
            <Row>
              <Col className="col-4">
                <p style={{ color: 'gray' }}>
                  {data.firstname} {data.lastname}
                </p>
              </Col>
              <Col className="col-4">
                <p style={{ color: 'gray' }}>{moment(data.createdOnUtc).format('MM/DD/yyyy  HH:mm:ss')}</p>
              </Col>
              <Col className="col-4">
                <p style={{ color: 'gray' }}>{data.status}</p>
              </Col>
            </Row>
          </>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleEditPersonalResponseModalClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handlePersonalResponseChange}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div className="respondersCard mt-3">
      <Tabs defaultActiveKey="active" id="uncontrolled-tab-example" className="tab" justify variant="pills">
        <Tab className="d-print-block" eventKey="active" title={activeTitle}>
          <Row className="locationCardTitle mb-5 mx-0">
            <Col className="mt-3">
              <p className="textLeft mx-4" style={{ marginTop: '5px' }}>
                Individuals Responding
              </p>
            </Col>
            <Col className="textRight mt-3 d-print-none">
              <Button variant="outline-primary" style={{ fontWeight: 'bold' }} onClick={handleAddResponderModalShow}>
                Add Responders
              </Button>
              {addRespondersModal}
              {editPersonalResponseModal}
            </Col>
          </Row>
          <Row className="mx-0">
            <Col>
              <Stack style={{ marginTop: '-10px' }}>
                <p className="responderDigit">{respondingStaffScene.length}</p>
                <p className="responderSubText" style={{ marginTop: '-10px' }}>
                  Responding
                </p>
              </Stack>
            </Col>
            <Col>
              <Stack style={{ marginTop: '-10px' }}>
                <p className="responderDigit">{arrivedStaffScene.length}</p>
                <p className="responderSubText" style={{ marginTop: '-10px' }}>
                  Arrived
                </p>
              </Stack>
            </Col>
          </Row>
          <div className="row mt-2">
            <div className="col mx-4">
              <p className="locationCardText textLeft" style={{ fontWeight: 'bold', paddingTop: '10px' }}>
                Responding
              </p>
            </div>
          </div>
          <div className="row mt-2 mx-0">
            {respondingStaffScene.length > 0 && (
              <div className="col mx-4 mb-3 respondingCard">
                {respondingStaffScene.map((data) => {
                  return (
                    <ResponderCard
                      data={data}
                      statusIcon={
                        (data.status === 'Responding To Scene' || data.status === 'Responding To Station') && (
                          <RespondingIcon size={'lg'} className="mt-2" />
                        )
                      }
                      handleEditPersonalResponseModalShow={handleEditPersonalResponseModalShow}
                    />
                  );
                })}
              </div>
            )}
            {respondingStaffScene.length === 0 && (
              <div className="col mx-4 mt-4 text-center">
                <p>There are no active units.</p>
              </div>
            )}
          </div>
          <div className="row mt-2 mx-0">
            <div className="col mx-4">
              <p className="locationCardText textLeft" style={{ fontWeight: 'bold', paddingTop: '10px' }}>
                Arrived
              </p>
            </div>
          </div>
          <div className="row mt-2 mx-0 mb-3">
            {arrivedStaffScene.length > 0 && (
              <div className="col mx-4 arrivedCard">
                {arrivedStaffScene.map((data) => {
                  return (
                    <ResponderCard
                      data={data}
                      statusIcon={
                        (data.status === 'Arrived On-Scene' || data.status === 'Arrived At Station') && (
                          <ArrivedIcon size={'lg'} className="mt-2" />
                        )
                      }
                      handleEditPersonalResponseModalShow={handleEditPersonalResponseModalShow}
                    />
                  );
                })}
              </div>
            )}
            {arrivedStaffScene.length === 0 && (
              <div className="col mx-4 mt-4 text-center">
                <p>There are no active units.</p>
              </div>
            )}
          </div>
        </Tab>
        <Tab className="d-print-none" eventKey="inactive" title={inactiveTitle}>
          <Row className="locationCardTitle mb-3">
            <Col className="mt-3">
              <p className="textLeft mx-4" style={{ marginTop: '5px' }}>
                Inactive Staff
              </p>
            </Col>
            <Col className="textRight mt-3">
              <Button variant="outline-primary" style={{ fontWeight: 'bold' }} onClick={handleAddResponderModalShow}>
                Add Responders
              </Button>
              {addRespondersModal}
            </Col>
          </Row>
          <div className="row mt-2">
            {inactiveStaff.length > 0 && (
              <div className="col mx-4 mb-3 closedCard">
                {inactiveStaff.map((data) => {
                  return (
                    <ResponderCard
                      data={data}
                      statusIcon={
                        data.status === 'Canceling' ? (
                          <CancelIcon size={'lg'} className="mt-2" />
                        ) : data.status === 'Clear' ? (
                          <ClosedIcon size={'lg'} className="mt-2" />
                        ) : (
                          ''
                        )
                      }
                      handleEditPersonalResponseModalShow={handleEditPersonalResponseModalShow}
                    />
                  );
                })}
              </div>
            )}
            {inactiveStaff.length === 0 && (
              <div className="col mx-4 mt-4 text-center">
                <p>There are no inactive units.</p>
              </div>
            )}
          </div>
        </Tab>
        <Tab className="d-print-none" eventKey="unavailable" title={unavailableTitle}>
          <Row className="locationCardTitle mb-3">
            <Col className="mt-3">
              <p className="textLeft mx-4" style={{ marginTop: '5px' }}>
                Unavailable Staff
              </p>
            </Col>
            <Col className="textRight mt-3">
              <Button variant="outline-primary" style={{ fontWeight: 'bold' }} onClick={handleAddResponderModalShow}>
                Add Responders
              </Button>
              {addRespondersModal}
            </Col>
          </Row>
          <div className="row mt-2 mb-3">
            {unavailableStaff.length > 0 && (
              <div className="col mx-4 unavailableCard">
                {unavailableStaff.map((data) => {
                  return (
                    <ResponderCard
                      data={data}
                      statusIcon={
                        data.status === 'Unavailable' ? (
                          <UnavailableIcon size={'lg'} className="mt-2" />
                        ) : data.status === 'Clear' ? (
                          <ClosedIcon size={'lg'} className="mt-2" />
                        ) : (
                          ''
                        )
                      }
                      handleEditPersonalResponseModalShow={handleEditPersonalResponseModalShow}
                    />
                  );
                })}
              </div>
            )}
            {unavailableStaff.length === 0 && (
              <div className="col mx-4 mt-4 text-center">
                <p>There are no unavailable units.</p>
              </div>
            )}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default ResponderMode;
