import { ApiResponseError } from './ApiResponseError';

export async function ensureSuccessStatusCodeAsync(response: Response): Promise<void> {
  if (response.ok) {
    return;
  }
  const statusCode = response?.status ?? 0;

  const message = `Status Code ${statusCode}`;
  throw new ApiResponseError(message, statusCode);
}

export async function ensureSuccessStatusCodeOrGetErrorTextAsync(response: Response, resource: string): Promise<void> {
  if (response.ok) {
    return;
  }
  const statusCode = response?.status ?? 0;

  // Return a useful Error message to display in the UI if possible.
  // If the API gives text with a 400, or if a 404, use the resource string to give a nice response.
  // Otherwise use 'Status Code XXX' response.
  const message =
    response.status === 400
      ? 'Error: ' + (await response.text())
      : response.status === 404
      ? `Error: ${resource} not found.`
      : `Status Code ${statusCode}`;

  throw new ApiResponseError(message, statusCode);
}

export function getApiUrlOrDefault(apiUrl: string | undefined, baseUrl: string): string {
  let apiUrlOrDefault: string | undefined = apiUrl;
  if (!apiUrlOrDefault) {
    apiUrlOrDefault = ensureTrailingSlash(baseUrl) + 'api/';
  }
  apiUrlOrDefault = ensureTrailingSlash(apiUrlOrDefault);
  return apiUrlOrDefault;
}

function ensureTrailingSlash(urlOrPath: string | undefined): string {
  if (!urlOrPath) {
    return '/';
  }

  if (urlOrPath[urlOrPath.length - 1] !== '/') {
    urlOrPath += '/';
  }
  return urlOrPath;
}
