import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSirenOn } from '@imagetrend/fontawesome-pro/pro-solid-svg-icons';
import { faFileChartColumn } from '@imagetrend/fontawesome-pro/sharp-regular-svg-icons';
import { useEffect, useState } from 'react';
import { Link, NavLink, useMatch } from 'react-router-dom';
import { useAppSelector } from '../../common/appState/appStateHooks';
import { selectClientModules } from '../../common/appState/clientModulesSlice';
import { selectClientAlias } from '../../common/appState/currentClientSlice';
import routes from '../../common/routesDefinitions';
import currentUser from '../../models/CurrentUser';
import modules from '../feature/modules';
import BaseSideNav from './baseNav';

const SideNav = () => {
  const mapIcon = <img src="images/liveTimeIncidents_icon.svg" className="mapIcon" alt="Live Time Incidents" />;
  // const unitsIcon = <img src="images/unitStatus_icon.svg" className="unitStatusIcon" alt="Unit Status" />;
  const searchIcon = <img src="images/incidentSearch_icon.svg" className="incidentSearchIcon" alt="Incident Search" />;
  const personnelIcon = <img src="images/incidentStatus_icon.svg" className="personnelIcon" alt="Personnel" />;
  const settingsIcon = <img src="images/settings_icon.svg" className="settingsIcon" alt="Settings" />;

  const [showCallTriageNav, setShowCallTriageNav] = useState(false);

  const clientAlias = useAppSelector(selectClientAlias);
  const clientModules = useAppSelector(selectClientModules);

  const isClientUser = !!clientAlias.clientID;
  const isAdmin = currentUser.isAdministrator(clientAlias);
  const isSuperAdmin = currentUser.isSignalAdmin();
  const isCallTriage = !!useMatch(`${routes.callTriage}/*`);

  useEffect(() => {
    const callTriageIndex = clientModules.enabledModules.findIndex((cm) => cm.name === modules.callTriage);

    if (callTriageIndex > -1) {
      if (!showCallTriageNav) {
        setShowCallTriageNav(true);
      }
    } else {
      if (showCallTriageNav) {
        setShowCallTriageNav(false);
      }
    }
  }, [clientModules, showCallTriageNav]);

  return (
    <>
      <BaseSideNav>
        {isClientUser && (
          <div>
            {showCallTriageNav && (
              // Using Link here to explicitly not automatically detect, so we can do it ourselves.
              <Link
                className={'text-center' + (isCallTriage ? ' active' : '')}
                to={routes.callTriageIncident}
                id="callTriageNav"
              >
                <FontAwesomeIcon icon={faSirenOn} size={'2x'} />
                <p>Call Triage</p>
              </Link>
            )}
            {!showCallTriageNav && clientAlias.clientID !== '0' && (
              <div>
                <NavLink to={routes.dashboard} id="dashboardNav" className="text-center">
                  {mapIcon}
                  <p>Live Time Incidents</p>
                </NavLink>
                {/* <div id="unitNav" className="text-center">
                      {unitsIcon}
                      <p>Unit Status</p>
                    </div> */}
                <NavLink to={routes.incidentSearch} id="incidentSearchNav" className="text-center">
                  {searchIcon}
                  <p>Incident Search</p>
                </NavLink>
              </div>
            )}
            {isAdmin && (
              <NavLink to={routes.reporting} id="reportsNav" className="text-center">
                <FontAwesomeIcon icon={faFileChartColumn} size={'2x'} />
                <p className="text-center">Reporting</p>
              </NavLink>
            )}
          </div>
        )}
        <div className="mt-auto">
          {isAdmin && (
            <>
              <NavLink to={routes.personnel} id="personnelNav">
                {personnelIcon}
                <p className="text-center">Personnel</p>
              </NavLink>
              <NavLink to={routes.adminSettings} id="adminSettingsNav">
                {settingsIcon}
                <p className="text-center">Settings</p>
              </NavLink>
              {isSuperAdmin && (
                <>
                  <NavLink to={routes.siteSettings} id="siteSettingsNav">
                    {settingsIcon}
                    <p className="text-center">Site Settings</p>
                  </NavLink>
                </>
              )}
            </>
          )}
        </div>
      </BaseSideNav>
    </>
  );
};

export default SideNav;
