import { PropsWithChildren, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ImmerReducer, useImmerReducer } from 'use-immer';
import { ReducerAction } from '../../common/reducerAction';
import Logo from '../logo/logo';
import { useApiClient } from '../useApiClient/useApiClient';

export type ResetPasswordParameters = {
  emailAddress: string;
  password: string;
  passwordConfirm: string;
};

const InitialState: ResetPasswordParameters = {
  emailAddress: '',
  password: '',
  passwordConfirm: '',
};

export const ResetPasswordActions = {
  emailAddress: 'emailAddress',
  password: 'password',
  passwordConfirm: 'passwordConfirm',
  reset: 'reset',
};

export const ResetPasswordReducer: ImmerReducer<ResetPasswordParameters, ReducerAction<ResetPasswordParameters>> = (
  draft,
  action
) => {
  switch (action.type) {
    case ResetPasswordActions.emailAddress:
      draft.emailAddress = action.data.emailAddress;
      break;
    case ResetPasswordActions.password:
      draft.password = action.data.password;
      break;
    case ResetPasswordActions.passwordConfirm:
      draft.passwordConfirm = action.data.passwordConfirm;
      break;
    case ResetPasswordActions.reset:
      draft.emailAddress = InitialState.emailAddress;
      draft.password = InitialState.password;
      draft.passwordConfirm = InitialState.passwordConfirm;
  }
};

type PasswordFormLabelProps = {
  required?: boolean;
} & PropsWithChildren;

function PasswordFormLabel(props: PasswordFormLabelProps) {
  return (
    <>
      <p className="form-title">
        {props.children}
        {!!props.required && <span className="d-inline text-danger"> *</span>}
      </p>
    </>
  );
}

export default function ResetPassword() {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const [searchParams] = useSearchParams();

  const [passwordResetForm, updatePasswordResetForm] = useImmerReducer(ResetPasswordReducer, InitialState);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  // Do we need to worry about accessing this page without a token?
  const token = searchParams.get('token');

  const handleEmailChange = (emailAddress: string) => {
    updatePasswordResetForm({
      type: ResetPasswordActions.emailAddress,
      data: {
        ...passwordResetForm,
        emailAddress: emailAddress,
      },
    });
  };

  const handlePasswordChange = (password: string) => {
    updatePasswordResetForm({
      type: ResetPasswordActions.password,
      data: {
        ...passwordResetForm,
        password: password ?? '',
      },
    });
  };

  const handlePasswordConfirmChange = (passwordConfirm: string) => {
    updatePasswordResetForm({
      type: ResetPasswordActions.passwordConfirm,
      data: {
        ...passwordResetForm,
        passwordConfirm: passwordConfirm ?? '',
      },
    });
  };

  const handleFormSubmit = (event: any) => {
    event.preventDefault();

    const resetPassword = async () => {
      if (passwordResetForm.password !== passwordResetForm.passwordConfirm) {
        setErrorMessages(['Passwords do not match']);
        return;
      }

      const data = await apiClient.Authentication.resetPassword({
        verificationToken: token ?? '',
        emailAddress: passwordResetForm.emailAddress,
        password: passwordResetForm.password,
      });
      if (!data.passwordReset) {
        setErrorMessages(data.errorMessages);
        return;
      }
      navigate('/');
      updatePasswordResetForm({
        type: ResetPasswordActions.reset,
        data: InitialState,
      });
    };
    resetPassword();
  };

  return (
    <div>
      <Row className="mx-0">
        <Col className="textRight">
          <Logo />
        </Col>
      </Row>
      <Container>
        <Row className="mx-0 justify-content-center">
          <Col xl={5} lg={12} md={9}>
            <div className="form-bg o-hidden form-border shadow-lg my-5">
              <Row className="mx-0">
                <Col lg={12}>
                  <h4 className="forgotHeader" id="forgotPanelTop">
                    Reset your Password
                  </h4>
                  <p className="forgotHeader mx-5">Type your account email address and new password.</p>
                  <div className="forgotContent">
                    <Form onSubmit={(e) => handleFormSubmit(e)}>
                      {errorMessages.length > 0 && (
                        <>
                          <Row className="mx-1 p-2">
                            <Col className="col-auto mx-auto">
                              <Alert
                                dismissible
                                onClose={() => setErrorMessages([])}
                                variant="danger"
                                show={errorMessages.length > 0}
                              >
                                <ul>
                                  {errorMessages.map((errorMessage) => (
                                    <li key={errorMessage}>{errorMessage}</li>
                                  ))}
                                </ul>
                              </Alert>
                            </Col>
                          </Row>
                        </>
                      )}
                      <Form.Group className="mb-3">
                        <PasswordFormLabel required>Email Address</PasswordFormLabel>
                        <Form.Control
                          type="text"
                          name="emailAddress"
                          onChange={(e) => handleEmailChange(e.target.value)}
                          value={passwordResetForm.emailAddress}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <PasswordFormLabel required>Password</PasswordFormLabel>
                        <Form.Control
                          type="password"
                          name="password"
                          onChange={(e) => handlePasswordChange(e.target.value)}
                          value={passwordResetForm.password}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <PasswordFormLabel required>Confirm Password</PasswordFormLabel>
                        <Form.Control
                          type="password"
                          name="passwordConfirm"
                          onChange={(e) => handlePasswordConfirmChange(e.target.value)}
                          value={passwordResetForm.passwordConfirm}
                          required
                        />
                      </Form.Group>
                      <Row className="my-1">
                        <Col>
                          <div className="textLeft">
                            <Link to="/" className="small">
                              Back to Login
                            </Link>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-4 mt-5 mx-1">
                        <Button type="submit" name="login" variant="primary" className="col-12">
                          SUBMIT
                        </Button>
                      </Row>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
