import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link, useLocation } from 'react-router-dom';
import { ImmerReducer, useImmerReducer } from 'use-immer';
import { ReducerAction } from '../../common/reducerAction';
import routes from '../../common/routesDefinitions';
import { ClientUserInvitationRequest } from '../../models/ClientUserInvitationRequest';
import { appendQueryString } from '../../services/api/querystrings';
import Logo from '../logo/logo';
import { useApiClient } from '../useApiClient/useApiClient';

type CreateAccountParameters = {
  email: string;
  firstname: string;
  lastname: string;
};

const InitialState: CreateAccountParameters = {
  email: '',
  firstname: '',
  lastname: '',
};

const CreateAccountActions = {
  emailAddress: 'emailAddress',
  firstname: 'firstname',
  lastname: 'lastname',
  reset: 'reset',
};

const CreateAccountReducer: ImmerReducer<CreateAccountParameters, ReducerAction<CreateAccountParameters>> = (
  draft,
  action
) => {
  switch (action.type) {
    case CreateAccountActions.emailAddress:
      draft.email = action.data.email;
      break;
    case CreateAccountActions.firstname:
      draft.firstname = action.data.firstname;
      break;
    case CreateAccountActions.lastname:
      draft.lastname = action.data.lastname;
      break;
    case CreateAccountActions.reset:
      draft.email = InitialState.email;
      draft.firstname = InitialState.firstname;
      draft.lastname = InitialState.lastname;
  }
};

export default function Create() {
  const [createAccountForm, updateCreateAccountForm] = useImmerReducer(CreateAccountReducer, InitialState);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [created, setCreated] = useState<boolean>(false);

  const apiClient = useApiClient();
  const location = useLocation();
  const searchParams: ClientUserInvitationRequest = location?.state?.searchParams;

  const handleEmailChange = (emailAddress: string) => {
    updateCreateAccountForm({
      type: CreateAccountActions.emailAddress,
      data: {
        ...createAccountForm,
        email: emailAddress,
      },
    });
  };

  const handleFirstnameChange = (firstname: string) => {
    updateCreateAccountForm({
      type: CreateAccountActions.firstname,
      data: {
        ...createAccountForm,
        firstname: firstname,
      },
    });
  };

  const handleLastnameChange = (lastname: string) => {
    updateCreateAccountForm({
      type: CreateAccountActions.lastname,
      data: {
        ...createAccountForm,
        lastname: lastname,
      },
    });
  };

  // checks email address formatting
  function NotValid(email: string): boolean {
    let valid = false;
    let EMAIL_REGEXP =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    if (email !== '' && (email.length <= 5 || !EMAIL_REGEXP.test(email))) {
      valid = true;
    }
    return valid;
  }

  const handleFormSubmit = async (event: any) => {
    event.preventDefault();
    // checks the user's entered email for formatting requirements,
    // then calls the API to check if that email is already in use
    if (!NotValid(createAccountForm.email)) {
      try {
        const response = await apiClient.Authentication.createAccount({
          ...createAccountForm,
        });

        if (!response.isCreated) {
          setErrorMessages(
            response.errorMessages.length > 0 ? response.errorMessages : ['Server responded with error']
          );
          return;
        }

        setCreated(true);
      } catch {
        setErrorMessages(['Server responded with error']);
      }
    }
  };

  return (
    <>
      <Row className="mx-0">
        <Col className="textRight">
          <Logo />
        </Col>
      </Row>
      <Container>
        <Row className="justify-content-center">
          <Col xl={5} lg={12} md={9} className="form-bg form-border shadow-lg my-5">
            <Card.Body className="p-0">
              <Row>
                <Col>
                  {!created ? (
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-white mb-4">Create a Signal account</h1>
                        <p className="text-white">These are the credentials you will use as your Signal login.</p>
                      </div>
                      <Form onSubmit={(e) => handleFormSubmit(e)}>
                        <Alert
                          dismissible
                          onClose={() => setErrorMessages([])}
                          variant="danger"
                          show={errorMessages.length > 0}
                        >
                          <ul>
                            {errorMessages.map((errorMessage, index) => (
                              <li key={index}>{errorMessage}</li>
                            ))}
                          </ul>
                        </Alert>
                        <Form.Group className="mb-3">
                          <Form.Label className="form-title" htmlFor="emailAddress">
                            Email Address
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="emailAddress"
                            name="emailAddress"
                            onChange={(e) => handleEmailChange(e.target.value)}
                            value={createAccountForm.email}
                            required
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label className="form-title" htmlFor="firstName">
                            First Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="firstName"
                            name="firstName"
                            onChange={(e) => handleFirstnameChange(e.target.value)}
                            value={createAccountForm.firstname}
                            required
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label className="form-title" htmlFor="lastName">
                            Last Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            id="lastName"
                            name="lastName"
                            onChange={(e) => handleLastnameChange(e.target.value)}
                            value={createAccountForm.lastname}
                            required
                          />
                        </Form.Group>
                        <Row className="mb-3 mx-1">
                          <Button type="submit" name="next" variant="primary">
                            Next Step
                          </Button>
                        </Row>
                      </Form>
                      <hr />
                      <div className="text-center">
                        <Link to={appendQueryString(routes.login, searchParams)}>Back to Login Page</Link>
                      </div>
                    </div>
                  ) : (
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-white mb-4">Create a Signal account</h1>
                        <p className="text-white">
                          An email has been sent to {createAccountForm.email} with instructions to finish activating
                          your account.
                        </p>
                      </div>
                      <hr />
                      <div className="text-center">
                        <Link to={appendQueryString(routes.login, searchParams)}>Back to Login Page</Link>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Col>
        </Row>
      </Container>
    </>
  );
}
