import { useEffect, useMemo, useState } from 'react';
import Col from 'react-bootstrap/Col';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { useImmerReducer } from 'use-immer';
import { User } from '../../models/User';
import HeaderTitle from '../headerTitle/headerTitle';
import SignalPagination from '../pagination/pagination';
import { OffsetLimit, PaginationReducer } from '../pagination/types';
import { useApiClient } from '../useApiClient/useApiClient';
import SendBulkEmailModal from './sendBulkEmail';
import UserCard from './userCard';

// enum ReducerActionType {
//   requiresLowercase = 'requiresLowercase',
//   reset = 'reset',
// }

// interface ReducerAction {
//   type: ReducerActionType;
//   payload: SiteSettings;
// }

// const settingsReducer: Reducer<SiteSettings, ReducerAction> = (state, action) => {
//   switch (action.type) {
//     case ReducerActionType.requiresLowercase:
//       return {
//         ...state,
//         passwordRequiresLowercase: action.payload.passwordRequiresLowercase,
//       };
//     case ReducerActionType.reset:
//       return init(action.payload);
//     default:
//       break;
//   }
//   return state;
// };

// const initialSettings: SiteSettings = {
//   passwordRequiresLowercase: false,
// };

// const init = (settings: SiteSettings): SiteSettings => {
//   return {
//     passwordRequiresLowercase: settings.passwordRequiresLowercase,
//   };
// };

export type SiteSettingsUser = User & {
  selected: boolean;
};

export default function SiteSettingsPage() {
  const defaultPagination: OffsetLimit = {
    offset: 0,
    limit: 50,
  };

  const apiClient = useApiClient();
  const user = JSON.parse(sessionStorage?.user ?? {});

  // const [siteSettings, dispatchSiteSettings] = useReducer(settingsReducer, initialSettings, init);
  const [users, setUsers] = useState<SiteSettingsUser[]>([]);
  const [paginationQuery, updatepaginationQuery] = useImmerReducer(PaginationReducer, defaultPagination);
  const [showModal, setShowModal] = useState<boolean>(false);

  // const changeSettings = useCallback(
  //   (settings: SiteSettings) => {
  //     const newSettings = { ...siteSettings, ...settings };
  //     const putSettings = async () => {
  //       return await apiClient.putSiteSettings(newSettings);
  //     };
  //     try {
  //       putSettings();
  //     } catch {
  //       throw Error('Unable to save site settings');
  //     }
  //   },
  //   [apiClient, siteSettings]
  // );

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const userListResult = (await apiClient.getAllUsers({ limit: 1000, offset: 0 })) as SiteSettingsUser[];

        setUsers(userListResult);
      } catch (error: any) {
        console.error('Failed to fetch user data for bulk actions', JSON.stringify(error));
      }
    };
    // const fetchSiteSettings = async () => {
    //   try {
    //     const siteSettings = await apiClient.getSiteSettings();
    //     dispatchSiteSettings({
    //       type: ReducerActionType.reset,
    //       payload: siteSettings,
    //     });
    //   } catch (error: any) {
    //     console.error('Failed to fetch site settings', JSON.stringify(error));
    //   }
    // };

    // fetchSiteSettings();
    fetchAllUsers();
  }, [apiClient]);

  // const onRequiresLowercaseSwitchAction = (requiresLowercaseFlag: boolean) => {
  //   const payload = {
  //     ...siteSettings,
  //     passwordRequiresLowercase: requiresLowercaseFlag,
  //   };
  //   dispatchSiteSettings({
  //     type: ReducerActionType.requiresLowercase,
  //     payload: payload,
  //   });
  //   changeSettings(payload);
  // };

  const changeUserAdmin = async (user: SiteSettingsUser, index: number) => {
    const newUser: SiteSettingsUser = {
      ...user,
      adminFlag: !user.adminFlag,
    };
    if (await apiClient.putUser(user.signalUserID, newUser)) {
      const newUsers = [...users];
      newUsers[index] = newUser;
      setUsers(newUsers);
    }
  };

  const changeUserSelect = (user: SiteSettingsUser, index: number) => {
    const newUser: SiteSettingsUser = {
      ...user,
      selected: !user.selected,
    };
    const newUsers = [...users];
    newUsers[index] = newUser;
    setUsers(newUsers);
  };

  const changeUserActive = async (user: SiteSettingsUser, index: number) => {
    const newUser: SiteSettingsUser = {
      ...user,
      activeFlag: !user.activeFlag,
    };
    if (await apiClient.putUser(user.signalUserID, newUser)) {
      const newUsers = [...users];
      newUsers[index] = newUser;
      setUsers(newUsers);
    }
  };

  const areAllSelected = useMemo(() => {
    return users.every((x) => x.selected);
  }, [users]);

  const selectBulk = () => {
    const newUsers = users.map((x) => ({
      ...x,
      selected: !areAllSelected,
    }));
    setUsers(newUsers);
  };

  const selectedUsers = useMemo(() => {
    return users.filter((x) => x.selected);
  }, [users]);

  const visibleUsers = useMemo(() => {
    return users.slice(paginationQuery.offset, paginationQuery.offset + paginationQuery.limit);
  }, [users, paginationQuery]);

  return (
    <>
      <HeaderTitle title={'Site Settings'} />
      <div className="incidentBG h-100">
        {/* <Form className="mx-3">
          <Form.Group className="mt-3">
            <Form.Check
              type="switch"
              id="privacy-switch"
              onChange={(event) => onRequiresLowercaseSwitchAction(event.target.checked)}
              checked={siteSettings.passwordRequiresLowercase}
              label="Password Requires Lowercase"
            />
          </Form.Group>
        </Form> */}
        {users.length > 0 ? (
          <div className="wrapper white-bg">
            <SendBulkEmailModal
              key={selectedUsers.length}
              showModal={showModal}
              setShowModal={setShowModal}
              userIds={selectedUsers.map((x) => x.signalUserID)}
            />
            <Row>
              <Col>
                <DropdownButton title="Bulk Actions">
                  <DropdownItem
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    Send Password Reset Email
                  </DropdownItem>
                </DropdownButton>
              </Col>
            </Row>
            <span className="text-normal">
              Click the column item to Activate or Inactivate a user, or change a user from Admin to User. Approve or
              Deny users that have requested access to your feed.
            </span>
            <Table hover className="list-table table table-striped" aria-label="Active Users">
              <thead>
                <tr>
                  <th className="text-center">
                    <Form.Check checked={areAllSelected} onChange={() => selectBulk()} />
                  </th>
                  <th>State</th>
                  <th>Role</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  {/* <th>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {visibleUsers.map((data, i) => {
                  return (
                    <UserCard
                      key={i}
                      mySignalUserID={user.signalUserID}
                      user={data}
                      handleUserRoleClick={() => changeUserAdmin(data, i)}
                      handleUserSelect={() => changeUserSelect(data, i)}
                      handleUserStateClick={() => changeUserActive(data, i)}
                    />
                  );
                })}
              </tbody>
            </Table>
            <SignalPagination
              paginationQuery={paginationQuery}
              updatePaginationQuery={updatepaginationQuery}
              total={users.length}
            />
          </div>
        ) : (
          <div className="wrapper white-bg">
            <span>No existing users found.</span>
          </div>
        )}
      </div>
    </>
  );
}
