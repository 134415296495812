import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.scss';
import SessionStorage from './SessionStorage';
import { useAppDispatch, useAppSelector } from './common/appState/appStateHooks';
import { hideNav, showNav } from './common/appState/navVisibleSlice';
import routes from './common/routesDefinitions';
import AdminSettings from './components/adminSettings/adminSettings';
import AgencySignup from './components/agencySignup/agencySignup';
import { AppContext, AppContextValue } from './components/appContext/appContext';
import Association from './components/association/association';
import CallTriageDestinations from './components/callTriage/destinations';
import CallTriageEventLog from './components/callTriage/eventLog';
import CallTriageIncidentDetails from './components/callTriage/incident/incidentDetails';
import Authenticated from './components/common/authenticated';
import Activate from './components/createAccount/activate';
import Create from './components/createAccount/create';
import Dashboard from './components/dashboard/dashboard';
import FeatureManagement from './components/feature/featureManagement';
import ForgotPassword from './components/forgotPassword/forgotPassword';
import ForgotPassword2 from './components/forgotPassword/forgotPassword2';
import ResetPassword from './components/forgotPassword/resetPassword';
import IncidentDetails from './components/incidentDetails/incidentDetails';
import IncidentSearch from './components/incidentSearch/incidentSearch';
import LoginPage from './components/login/loginPage';
import Navigation from './components/navbar/navbar';
import RightSideNav from './components/navbar/rightSideNav';
import SideNav from './components/navbar/sideNav';
import Personnel from './components/personnel/personnel';
import CallTriageDispositionsByUserReport from './components/reports/callTriage/dispositionsByUserReport';
import CallTriageTransportsByAgencyReport from './components/reports/callTriage/transportsByAgencyReport';
import CallTriageTransportsByDestinationReport from './components/reports/callTriage/transportsByDestinationReport';
import ReportsPage from './components/reports/reportsPage';
import ResponderActionByPersonnelReport from './components/reports/responder/actionByPersonnelReport';
import ResponderStatusByIncidentReport from './components/reports/responder/statusByIncidentReport';
import ResponderStatusByPersonnelReport from './components/reports/responder/statusByPersonnelReport';
import AuthRoute from './components/security/AuthRoute';
import RequireClientAdminRoute from './components/security/RequireClientAdminRoute';
import { createApiClient } from './services/api/ApiClient';
import RequireSignalAdminRoute from './components/security/RequireSignalAdminRoute';
import SiteSettingsPage from './components/siteSettings/siteSettings';
import Activate2 from './components/createAccount/activate2';

const queryClient = new QueryClient();
export interface AppProps {
  baseUrl: string | undefined;
}

function App({ baseUrl }: AppProps) {
  const apiClient = createApiClient({ baseUrl });
  const appContextValue: AppContextValue = {
    apiClient,
    appVersion: process.env.REACT_APP_VERSION,
  };
  const [contentWrapperClass, setContentWrapperClass] = useState('contentWrapper');
  const [mainContentClass, setMainContentClass] = useState('mainContent');

  const navVisible = useAppSelector((state) => state.showNav.visible);
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    // Watch for location changes so we can do layout type things such as changing
    // the margins on the main-content div when we're on the login page for example

    const parsedPath = location.pathname.split('/', 2);

    if (parsedPath.length > 1) {
      if (!parsedPath[0] && !parsedPath[1]) {
        setContentWrapperClass('contentWrapper contentWrapper-login');
        setMainContentClass('mainContent mainContent-login no-nav');
        dispatch(hideNav());
      } else {
        const routePath = parsedPath[1];

        // the routes to hide the nav menus from
        const hideNavRoutes = [
          routes.activate,
          routes.activate2,
          routes.create,
          routes.forgot,
          routes.forgot2,
          routes.resetPassword,
        ];

        if (hideNavRoutes.some((hiddenRoute) => routePath === hiddenRoute.slice(1))) {
          setContentWrapperClass(`contentWrapper contentWrapper-${routePath}`);
          setMainContentClass(`mainContent mainContent-${routePath} no-nav`);
          if (navVisible) {
            dispatch(hideNav());
          }
        } else {
          setContentWrapperClass(`contentWrapper contentWrapper-${routePath}`);
          setMainContentClass(`mainContent mainContent-${routePath} d-flex flex-column`);
          if (!navVisible) {
            dispatch(showNav());
          }
        }
      }
    }
  }, [location, dispatch, navVisible]);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AppContext.Provider value={appContextValue}>
          {navVisible && <Navigation />}
          <div className={contentWrapperClass}>
            {navVisible && <SideNav />}
            <div className={mainContentClass}>
              <Routes>
                <Route path={routes.login} element={<LoginPage />} />
                <Route path={routes.activate} element={<Activate />} />
                <Route path={routes.activate2} element={<Activate2 />} />
                <Route path={routes.create} element={<Create />} />
                <Route path={routes.associate} element={<Association />} />
                <Route path={routes.forgot} element={<ForgotPassword />} />
                <Route path={routes.forgot2} element={<ForgotPassword2 />} />
                <Route path={routes.resetPassword} element={<ResetPassword />} />
                <Route element={<AuthRoute />}>
                  <Route path="/incidentDetails/:id" element={<IncidentDetails />} />
                  <Route path={routes.incidentSearch} element={<IncidentSearch />} />
                  <Route path={routes.dashboard} element={<Dashboard />} />
                  <Route path="/agencySignup" element={<AgencySignup />} />
                  <Route path={routes.callTriageIncident} element={<CallTriageIncidentDetails isCreate />} />
                  <Route
                    path={routes.callTriageEditIncident}
                    element={<CallTriageIncidentDetails isCreate={false} />}
                  />
                  <Route path={routes.callTriageDestinations} element={<CallTriageDestinations />} />
                  <Route path={routes.callTriageEventLog} element={<CallTriageEventLog />} />
                  <Route element={<RequireClientAdminRoute />}>
                    <Route path={routes.reporting} element={<ReportsPage />} />
                    <Route
                      path={routes.responderActionByPersonnelReport}
                      element={<ResponderActionByPersonnelReport />}
                    />
                    <Route
                      path={routes.responderStatusByPersonnelReport}
                      element={<ResponderStatusByPersonnelReport />}
                    />
                    <Route
                      path={routes.responderStatusByIncidentReport}
                      element={<ResponderStatusByIncidentReport />}
                    />
                    <Route
                      path={routes.callTriageTransportsByAgencyReport}
                      element={<CallTriageTransportsByAgencyReport />}
                    />
                    <Route
                      path={routes.callTriageTransportsByDestinationReport}
                      element={<CallTriageTransportsByDestinationReport />}
                    />
                    <Route
                      path={routes.callTriageDispositionsByUserReport}
                      element={<CallTriageDispositionsByUserReport />}
                    />
                    <Route path={routes.adminSettings} element={<AdminSettings />} />
                    <Route path={routes.personnel} element={<Personnel />} />
                  </Route>
                  <Route element={<RequireSignalAdminRoute />}>
                    <Route path={routes.siteSettings} element={<SiteSettingsPage />} />
                  </Route>
                </Route>
              </Routes>
              <Authenticated>
                <FeatureManagement />
              </Authenticated>
            </div>
            {navVisible && <RightSideNav />}
          </div>
          <SessionStorage />
        </AppContext.Provider>
      </QueryClientProvider>
    </>
  );
}

export default App;
