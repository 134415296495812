import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../logo/logo';

export default function ForgotPassword2() {
  const location = useLocation();
  const emailAddress = location?.state?.emailAddress;

  return (
    <div>
      <Row className="mx-0">
        <Col className="textRight">
          <Logo />
        </Col>
      </Row>
      <Container>
        <Row className="mx-0 justify-content-center">
          <Col xl={5} lg={12} md={9}>
            <div className="form-bg o-hidden form-border shadow-lg my-5">
              <Row className="mx-0">
                <Col lg={12}>
                  <h4 className="forgotHeader" id="forgotPanelTop">
                    Forgot your password?
                  </h4>
                  <p className="forgotHeader mx-5">
                    {emailAddress
                      ? `An email has been sent ${
                          emailAddress ? `to ${emailAddress} ` : ''
                        }with instructions to reset your password.`
                      : 'An email has been sent with instructions to reset your password.'}
                  </p>
                  <Row className="my-1">
                    <Col>
                      <Link to="/" className="small">
                        Back to Login
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
