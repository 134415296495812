import { Responder } from '../../models/Responder';
import { PencilIcon } from '../faIconImports/fontAwesomeIcons';

interface ResponderCardProps {
  data: Responder;
  statusIcon?: React.ReactNode;
  handleEditPersonalResponseModalShow: (e: any) => void;
}

const ResponderCard = ({ data, statusIcon, handleEditPersonalResponseModalShow }: ResponderCardProps) => {
  const clientListObject = JSON.parse(sessionStorage.clientList);
  const userObject = {
    signalUserID: clientListObject[0].signalUserID,
    firstName: clientListObject[0].firstname,
    lastName: clientListObject[0].lastname,
  };

  return (
    <>
      <div key={data.dispatchResponderID}>
        <div className="responderHover">
          <div className="row responderRow">
            <div className="col">
              <p className="responderCardText">
                {data.firstname} {data.lastname}
                {data.signalUserID === userObject.signalUserID && (
                  <PencilIcon onClick={handleEditPersonalResponseModalShow} size={'lg'} className="ml-2" />
                )}
              </p>
            </div>
            <div className="col textRight">{statusIcon}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResponderCard;
