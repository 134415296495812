import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ImmerReducer, useImmerReducer } from 'use-immer';
import { ReducerAction } from '../../common/reducerAction';
import routes from '../../common/routesDefinitions';
import { appendQueryString } from '../../services/api/querystrings';
import Logo from '../logo/logo';
import { useApiClient } from '../useApiClient/useApiClient';

type ActivateAccountParameters = {
  password: string;
  confirmPassword: string;
};

const InitialState: ActivateAccountParameters = {
  password: '',
  confirmPassword: '',
};

const ActivateAccountActions = {
  password: 'password',
  confirmPassword: 'confirmPassword',
  reset: 'reset',
};

const ActivateAccountReducer: ImmerReducer<ActivateAccountParameters, ReducerAction<ActivateAccountParameters>> = (
  draft,
  action
) => {
  switch (action.type) {
    case ActivateAccountActions.password:
      draft.password = action.data.password;
      break;
    case ActivateAccountActions.confirmPassword:
      draft.confirmPassword = action.data.confirmPassword;
      break;
    case ActivateAccountActions.reset:
      draft.password = InitialState.password;
      draft.confirmPassword = InitialState.confirmPassword;
  }
};

export default function Activate() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const apiClient = useApiClient();

  const account = searchParams.get('account') ?? '';
  const token = searchParams.get('token') ?? '';

  const [activateAccountForm, updateActivateAccountForm] = useImmerReducer(ActivateAccountReducer, InitialState);

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const handlePasswordChange = (password: string) => {
    updateActivateAccountForm({
      type: ActivateAccountActions.password,
      data: {
        ...activateAccountForm,
        password,
      },
    });
  };

  const handleConfirmPasswordChange = (confirmPassword: string) => {
    updateActivateAccountForm({
      type: ActivateAccountActions.confirmPassword,
      data: {
        ...activateAccountForm,
        confirmPassword,
      },
    });
  };

  const handleFormSubmit = async (event: any) => {
    event.preventDefault();
    if (activateAccountForm.password !== activateAccountForm.confirmPassword) {
      setErrorMessages(['The passwords you entered did not match.']);
      return;
    }
    const response = await apiClient.Authentication.activateAccount({
      signalUserId: account,
      verificationCode: token,
      password: activateAccountForm.password,
    });

    if (response.errorMessages?.length > 0) {
      // alerts the user that an error occurred
      setErrorMessages(response.errorMessages);
      return;
    }

    // Do an immediate login?  We'd need the email...
    navigate(routes.activate2);

    updateActivateAccountForm({
      type: ActivateAccountActions.reset,
      data: {
        ...InitialState,
      },
    });
  };

  return (
    <>
      <Row className="mx-0">
        <Col className="textRight">
          <Logo />
        </Col>
      </Row>
      <Container>
        <Row className="mx-0 justify-content-center">
          <Col xl={5} lg={12} md={9}>
            <div className="form-bg o-hidden form-border shadow-lg my-5">
              <Row className="mx-0">
                <Col lg={12}>
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-white mb-4">Activate your Signal Account</h1>
                    </div>
                    <Form onSubmit={(e) => handleFormSubmit(e)}>
                      <Alert
                        dismissible
                        onClose={() => setErrorMessages([])}
                        variant="danger"
                        show={errorMessages.length > 0}
                      >
                        <ul>
                          {errorMessages.map((errorMessage) => (
                            <li>{errorMessage}</li>
                          ))}
                        </ul>
                      </Alert>
                      <p className="form-title">Create a Password</p>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="password"
                          name="password"
                          onChange={(e) => handlePasswordChange(e.target.value)}
                          value={activateAccountForm.password}
                          required
                        />
                      </Form.Group>
                      <p className="form-title">Confirm Password</p>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="password"
                          name="confirmPassword"
                          onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                          value={activateAccountForm.confirmPassword}
                          required
                        />
                      </Form.Group>
                      <div className="row mb-3 mx-1">
                        <Button type="submit" name="next" variant="primary">
                          Next Step
                        </Button>
                      </div>
                      <div className="row mx-1"></div>
                    </Form>
                    <div className="text-center text-white">
                      <Link to={appendQueryString(routes.login, searchParams)}>Back to Login Page</Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
