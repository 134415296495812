import { Dispatch, Reducer, SetStateAction, useReducer } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import SignalModal from '../common/signalModal';
import { useApiClient } from '../useApiClient/useApiClient';

interface SendBulkEmailModalProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  userIds: string[];
}

interface SendSettings {
  loading: RunningState;
  count: number;
}

enum ReducerActionType {
  loading = 'loading',
  count = 'count',
  reset = 'reset',
}

enum RunningState {
  NotStarted,
  Running,
  Finished,
}

interface ReducerAction {
  type: ReducerActionType;
  payload: SendSettings;
}

const settingsReducer: Reducer<SendSettings, ReducerAction> = (state, action) => {
  switch (action.type) {
    case ReducerActionType.loading:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case ReducerActionType.count:
      return {
        ...state,
        count: action.payload.count,
      };
    case ReducerActionType.reset:
      return init(action.payload);
    default:
      break;
  }
  return state;
};

const init = (settings: SendSettings): SendSettings => {
  return {
    loading: settings.loading,
    count: settings.count,
  };
};

export default function SendBulkEmailModal({ showModal, setShowModal, userIds, ...props }: SendBulkEmailModalProps) {
  const apiClient = useApiClient();

  const initialSettings: SendSettings = {
    loading: RunningState.NotStarted,
    count: 0,
  };
  const [settings, dispatch] = useReducer(settingsReducer, initialSettings, init);

  const sendBulkResetRequest = async () => {
    if (settings.loading !== RunningState.NotStarted) {
      return;
    }
    dispatch({
      type: ReducerActionType.loading,
      payload: {
        ...settings,
        loading: RunningState.Running,
      },
    });
    var response = await apiClient.Admin.bulkReset({
      userIds: userIds,
    });
    dispatch({
      type: ReducerActionType.count,
      payload: {
        ...settings,
        count: response.count,
      },
    });
    dispatch({
      type: ReducerActionType.loading,
      payload: {
        ...settings,
        loading: RunningState.Finished,
      },
    });
  };

  return (
    <SignalModal
      show={showModal}
      toggleShow={() => setShowModal(false)}
      confirm={() => sendBulkResetRequest()}
      overrideConfirmClose
      keepOpen={settings.loading === RunningState.Running}
      hideChoices={settings.loading === RunningState.Running}
      confirmText={settings.loading === RunningState.NotStarted ? 'Send Emails' : undefined}
      negateText={settings.loading === RunningState.NotStarted ? 'Do Not Send' : 'Done'}
      headerTitle="Bulk Password Reset"
    >
      {settings.loading === RunningState.NotStarted && (
        <>You are about to send emails to {userIds.length + (userIds.length !== 1 ? ' users' : ' user')}.</>
      )}
      {settings.loading === RunningState.Running && (
        <>
          <Spinner /> Sending Emails...
        </>
      )}
      {settings.loading === RunningState.Finished && (
        <>{settings.count + (settings.count !== 1 ? ' emails were' : ' email was')} sent.</>
      )}
    </SignalModal>
  );
}
