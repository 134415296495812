import { Formik } from 'formik';
import { Reducer, useEffect, useReducer, useRef, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useAppSelector } from '../../../common/appState/appStateHooks';
import { selectClientId } from '../../../common/appState/currentClientSlice';
import { ReducerAction } from '../../../common/reducerAction';
import { Office } from '../../../models/ResourceBridge/Office';
import configApiClient from '../../../services/api/ConfigurationApiClient';
import SignalLoading from '../../common/signalLoading';
import { useApiClient } from '../../useApiClient/useApiClient';
import DestinationCollection from '../destinationCollection';
import SignalMap from '../googleMaps/signalMap';
import CallTriageRightHandNav from '../rightHandNav';
import CallTriageTopNav from '../topNav';
import { IncidentOptions, incidentSchema, initialIncident, initialPatient } from './incidentDetails.types';
import CallTriageIncidentInformation from './incidentInformation';

interface CallTriageIncidentDetailsProps {
  isCreate: boolean;
}

enum IncidentOptionsAction {
  setGenders = 'setGenders',
  setAgeUnits = 'setAgeUnits',
  setIncidentStatuses = 'setIncidentStatuses',
  setIncidentTypes = 'setIncidentTypes',
  setIncidentSubtypes = 'setIncidentSubtypes',
  setCommonPlaces = 'setCommonPlaces',
  setAgencies = 'setAgencies',
  setIncidentDispositions = 'setIncidentDispositions',
  setDestinations = 'setDestinations',
}

const initialOptions: IncidentOptions = {
  genders: [],
  ageUnits: [],
  incidentStatuses: [],
  incidentDispositions: [],
  incidentTypes: [],
  incidentSubtypes: [],
  commonPlaces: [],
  agencies: [],
  destinations: [],
};

const optionsReducer: Reducer<IncidentOptions, ReducerAction<IncidentOptions>> = (state, action) => {
  switch (action.type) {
    case IncidentOptionsAction.setGenders:
      return {
        ...state,
        genders: action.data.genders,
      };
    case IncidentOptionsAction.setAgeUnits:
      return {
        ...state,
        ageUnits: action.data.ageUnits,
      };
    case IncidentOptionsAction.setIncidentTypes:
      return {
        ...state,
        incidentTypes: action.data.incidentTypes,
      };
    case IncidentOptionsAction.setIncidentSubtypes:
      return {
        ...state,
        incidentSubtypes: action.data.incidentSubtypes,
      };
    case IncidentOptionsAction.setIncidentDispositions:
      return {
        ...state,
        incidentDispositions: action.data.incidentDispositions,
      };
    case IncidentOptionsAction.setIncidentStatuses:
      return {
        ...state,
        incidentStatuses: action.data.incidentStatuses,
      };
    case IncidentOptionsAction.setCommonPlaces:
      return {
        ...state,
        commonPlaces: action.data.commonPlaces,
      };
    case IncidentOptionsAction.setAgencies:
      return {
        ...state,
        agencies: action.data.agencies,
      };
    case IncidentOptionsAction.setDestinations:
      return {
        ...state,
        destinations: action.data.destinations,
      };
  }
  return state;
};

const CallTriageIncidentDetails = ({ isCreate }: CallTriageIncidentDetailsProps) => {
  const apiClient = useApiClient();
  const clientId = useAppSelector(selectClientId);
  const [isEditable, setEditable] = useState<boolean>(isCreate);
  const [googleMapsApiKey, setGoogleMapsApiKey] = useState<string>();
  const [options, dispatchOptions] = useReducer(optionsReducer, initialOptions);
  const googleMapInstance = useRef<google.maps.Map>();
  useEffect(() => {
    const getMapsApiKey = async () => {
      const mapsApiKey = await configApiClient.getGoogleMapsApiKey();
      if (mapsApiKey !== googleMapsApiKey) {
        setGoogleMapsApiKey(mapsApiKey);
      }
    };

    getMapsApiKey();
  }, [googleMapsApiKey, setGoogleMapsApiKey]);

  useEffect(() => {
    setEditable(isCreate);
  }, [isCreate, setEditable]);

  useEffect(() => {
    const loadResources = async () => {
      const rGenders = await apiClient.getResourceGenders();
      dispatchOptions({
        type: IncidentOptionsAction.setGenders,
        data: {
          ...initialOptions,
          genders: rGenders.map((g) => ({
            value: g.resourceID,
            label: g.description,
          })),
        },
      });
    };
    loadResources();
  }, [apiClient, dispatchOptions]);

  useEffect(() => {
    const loadResources = async () => {
      const rUnits = await apiClient.getResourceAgeUnits();
      const yearAgeUnits = rUnits.find((x) => x.description === 'Years');
      if (yearAgeUnits) {
        initialPatient.ageUnitsID = yearAgeUnits.resourceID;
        initialIncident.patients.forEach((x) => (x.ageUnitsID = yearAgeUnits.resourceID));
      }
      dispatchOptions({
        type: IncidentOptionsAction.setAgeUnits,
        data: {
          ...initialOptions,
          ageUnits: rUnits.map((x) => ({
            value: x.resourceID,
            label: x.description,
          })),
        },
      });
    };
    loadResources();
  }, [apiClient, dispatchOptions]);

  useEffect(() => {
    const loadResources = async () => {
      const statuses = await apiClient.getCallTriageIncidentStatuses();
      const newStatus = statuses.find((x) => x.name === 'New');
      if (newStatus) {
        initialIncident.incidentStatusID = newStatus.incidentStatusID;
      }
      dispatchOptions({
        type: IncidentOptionsAction.setIncidentStatuses,
        data: {
          ...initialOptions,
          incidentStatuses: statuses.map((x) => ({
            value: x.incidentStatusID,
            label: x.name,
          })),
        },
      });
    };
    loadResources();
  }, [apiClient, dispatchOptions]);

  useEffect(() => {
    const loadResources = async () => {
      const dispositions = await apiClient.getCallTriageIncidentDispositions();
      dispatchOptions({
        type: IncidentOptionsAction.setIncidentDispositions,
        data: {
          ...initialOptions,
          incidentDispositions: dispositions.map((x) => ({
            value: x.incidentDispositionID,
            label: x.name,
          })),
        },
      });
    };
    loadResources();
  }, [apiClient, dispatchOptions]);

  useEffect(() => {
    const loadResources = async () => {
      const types = await apiClient.getCallTriageIncidentTypes();
      dispatchOptions({
        type: IncidentOptionsAction.setIncidentTypes,
        data: {
          ...initialOptions,
          incidentTypes: types.map((x) => ({
            value: x.incidentTypeID,
            label: x.name,
          })),
        },
      });
    };
    loadResources();
  }, [apiClient, dispatchOptions]);

  useEffect(() => {
    const loadResources = async () => {
      const subtypes = await apiClient.getCallTriageIncidentSubtypes();
      dispatchOptions({
        type: IncidentOptionsAction.setIncidentSubtypes,
        data: {
          ...initialOptions,
          incidentSubtypes: subtypes.map((x) => ({
            value: x.incidentSubtypeID,
            label: x.name,
            ...x,
          })),
        },
      });
    };
    loadResources();
  }, [apiClient, dispatchOptions]);

  useEffect(() => {
    const loadResources = async () => {
      const commonPlaces = await apiClient.getCallTriageCommonPlaces(clientId);
      dispatchOptions({
        type: IncidentOptionsAction.setCommonPlaces,
        data: {
          ...initialOptions,
          commonPlaces: commonPlaces.map((x) => ({
            value: x.commonPlaceID,
            label: x.name,
            ...x,
          })),
        },
      });
    };
    loadResources();
  }, [apiClient, clientId, dispatchOptions]);

  useEffect(() => {
    const loadResources = async () => {
      const agencies = (await apiClient.getCallTriageAgencies(clientId)).sort((a, b) =>
        a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1
      );
      dispatchOptions({
        type: IncidentOptionsAction.setAgencies,
        data: {
          ...initialOptions,
          agencies: agencies.map((x) => ({
            value: x.agencyID,
            label: x.name,
          })),
        },
      });
    };
    loadResources();
  }, [apiClient, clientId, dispatchOptions]);

  useEffect(() => {
    const loadResources = async () => {
      const destinations = await apiClient.getDestinations();
      dispatchOptions({
        type: IncidentOptionsAction.setDestinations,
        data: {
          ...initialOptions,
          destinations: destinations.map((x) => ({
            value: x.agency_id,
            label: x.agencyName,
            ...x,
          })),
        },
      });
    };
    loadResources();
  }, [apiClient, clientId, dispatchOptions]);

  const isLoading =
    options.ageUnits.length === 0 || options.incidentStatuses.length === 0 || options.destinations.length === 0;

  return (
    <>
      <CallTriageRightHandNav />
      <CallTriageTopNav />
      {isLoading ? (
        <SignalLoading />
      ) : (
        <>
          <Formik initialValues={{ ...initialIncident }} validationSchema={incidentSchema} onSubmit={() => undefined}>
            {(formik) => (
              <Row className="m-0">
                <Col md={6} className={'border-primary'}>
                  <Card className="m-1" bg="it-light-gray">
                    <Card.Body className="rounded">
                      {googleMapsApiKey && (
                        <CallTriageIncidentInformation
                          isCreate={isCreate}
                          isEditable={isEditable}
                          setEditable={setEditable}
                          googleMapsApiKey={googleMapsApiKey}
                          formik={formik}
                          options={options}
                        />
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card className="m-1 map-card" bg="it-light-gray">
                    <Card.Body className="p-0 rounded">
                      <Tabs fill justify variant="pills">
                        <Tab eventKey={'map'} title={'Map'}>
                          {googleMapsApiKey && (
                            <SignalMap
                              mapContainerClassName="m-1"
                              isEditable={isEditable}
                              incident={formik.values}
                              setFieldValue={formik.setFieldValue}
                              googleMapsApiKey={googleMapsApiKey}
                              onMapLoaded={(map: google.maps.Map) => (googleMapInstance.current = map)}
                            />
                          )}
                        </Tab>
                        <Tab
                          aria-required={!!formik.errors.finalDestinationID}
                          tabClassName={!!formik.errors.finalDestinationID ? 'is-invalid' : ''}
                          disabled={!formik.values.latitude || !formik.values.longitude}
                          eventKey={'destinations'}
                          title={'Destinations'}
                        >
                          <DestinationCollection
                            small
                            incident={formik.values}
                            setFieldValue={formik.setFieldValue}
                            errors={formik.errors}
                            destinationsPerPage={5}
                            isEditable={isEditable}
                            destinations={options.destinations}
                            onSelect={(destination: Office) => {
                              const newDisp = options.incidentDispositions.find(
                                (x) => x.label === 'Destination Assigned'
                              );
                              // Validation is asynchronous, so these values need to be set simultaneously.
                              const newValues = { ...formik.values };
                              if (destination.id === formik.values.finalDestinationID) {
                                newValues.finalDestinationID = null;
                                newValues.finalDestinationName = null;
                                if (formik.values.incidentDispositionID === newDisp?.value) {
                                  newValues.incidentDispositionID = null;
                                }
                              } else {
                                newValues.finalDestinationID = destination.id;
                                newValues.finalDestinationName = destination.agencyName;
                                if (newDisp && !formik.values.incidentDispositionID) {
                                  newValues.incidentDispositionID = newDisp.value;
                                }
                              }
                              formik.setValues(newValues, true);
                            }}
                          />
                        </Tab>
                      </Tabs>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default CallTriageIncidentDetails;
