import moment from 'moment';
import api from '../../common/api';
import { SignalResource } from '../../common/resource';
import {
  CallTriageAgency,
  CallTriageCommonPlace,
  CallTriageIncidentDisposition,
  CallTriageIncidentStatus,
  CallTriageIncidentSubtype,
  CallTriageIncidentType,
} from '../../components/callTriage/types';
import { Client } from '../../models/Client';
import { ClientSettings } from '../../models/ClientSettings';
import { ClientUser } from '../../models/ClientUser';
import { ClientUserInvitation } from '../../models/ClientUserInvitation';
import { ClientUserRequest } from '../../models/ClientUserRequest';
import { Country } from '../../models/Country';
import { County } from '../../models/County';
import { getData, postData, putData } from '../../models/Helpers/FetchHelper';
import { Incident } from '../../models/Incident';
import { ListQuery } from '../../models/ListQuery';
import { NotesQuery } from '../../models/NotesQuery';
import { Psap } from '../../models/Psap';
import { PsapQuery } from '../../models/PsapQuery';
import { RequestOptions } from '../../models/RequestOptions';
import { Office } from '../../models/ResourceBridge/Office';
import { SignalEvent } from '../../models/SignalEvent';
import { SiteSettings } from '../../models/SiteSettings';
import { State } from '../../models/State';
import { TimeZone } from '../../models/TimeZone';
import { User } from '../../models/User';
import { createAuthenticationApiClient } from './AuthenticationApiClient';
import { createIncidentApiClient } from './IncidentApiClient';
import { appendQueryString } from './querystrings';
import { ensureSuccessStatusCodeAsync, ensureSuccessStatusCodeOrGetErrorTextAsync, getApiUrlOrDefault } from './shared';
import { createAdminApiClient } from './AdminApiClient';

export function createApiClient(options?: { baseUrl?: string; apiUrl?: string }) {
  const baseUrl = `${api.baseUrl}/`;
  const apiUrl = getApiUrlOrDefault(options?.apiUrl, baseUrl);
  const client = Object.freeze({
    Admin: createAdminApiClient(options),
    Authentication: createAuthenticationApiClient(options),
    Incidents: createIncidentApiClient(options),
    // Dispatch Incidents
    listIncidentsAsync: async (
      clientAlias: { clientID: string; clientName: string },
      query?: ListQuery
    ): Promise<Incident[]> => {
      const requestPath = appendQueryString(`${apiUrl}clients/${clientAlias.clientID}/DispatchIncidents/`, query);

      const response = await fetch(requestPath, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.accessToken}`,
        },
      });
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson as Incident[];
    },
    getIncidentById: async (clientID: string, incidentId: string | number): Promise<Incident> => {
      const requestPath = `${apiUrl}clients/${clientID}/DispatchIncidents/${incidentId}`;

      const response = await fetch(requestPath, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.accessToken}`,
        },
      });
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson as Incident;
    },
    // Dispatch Comments
    getNotesAsync: async function (query?: NotesQuery) {
      const incident = JSON.parse(sessionStorage.incident);
      const clientAlias = JSON.parse(sessionStorage.clientAlias);
      const requestPath = appendQueryString(
        `${apiUrl}clients/${clientAlias.clientID}/DispatchIncidents/${incident}/DispatchComments`,
        query
      );

      const response = await fetch(requestPath, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.accessToken}`,
        },
      });
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson;
    },
    // Dispatch Responders
    getResponders: async function (query: NotesQuery) {
      const incident = JSON.parse(sessionStorage.incident);
      const client = JSON.parse(sessionStorage.clientAlias);
      const requestPath = appendQueryString(
        `${apiUrl}clients/${client.clientID}/DispatchIncidents/${incident}/dispatchresponders`,
        query
      );

      const response = await fetch(requestPath, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.accessToken}`,
        },
      });
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson;
    },
    getResponderHistoryByID: async function (query: '') {
      const client = JSON.parse(sessionStorage.clientAlias);
      const today = moment().add(1, 'days').format('yyyy-MM-DD');
      const yesterday = moment().subtract(1, 'days').format('yyyy-MM-DD');
      const requestPath = appendQueryString(
        `${apiUrl}clients/${client.clientID}/DispatchIncidents/DispatchResponderHistorys?limit=15&datefrom=${yesterday}&dateto=${today}`,
        query
      );

      const response = await fetch(requestPath, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.accessToken}`,
        },
      });
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson;
    },
    getStatesList: async (): Promise<State[]> => {
      const requestPath = './data/states.json';
      const response = await fetch(requestPath, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      });
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson as State[];
    },
    getCountiesList: async (): Promise<County[]> => {
      const requestPath = './data/counties.json';
      const response = await fetch(requestPath, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      });
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson as County[];
    },
    getDestinations: async (): Promise<Array<Office>> => {
      const response = await fetch(`${apiUrl}Destinations`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.accessToken}`,
        },
      });
      await ensureSuccessStatusCodeAsync(response);

      const destinations = (await response.json()) as Array<Office>;
      const filteredDestinations = destinations
        // @TODO: Remove this when Resource Bridge adds a settings.
        // This is terrible, horrible, no good, very bad code.
        .filter((x) => x.agencyName !== '*Georgia Coordinating Center (GCC)' && x.agencyName !== 'Test/Demo')
        .sort((a, b) => a.agencyName.localeCompare(b.agencyName));

      return filteredDestinations;
    },

    getCountriesList: async (): Promise<Country[]> => {
      const requestPath = './data/countries.json';
      const response = await fetch(requestPath, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      });
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson as Country[];
    },

    getTimeZoneList: async (): Promise<TimeZone[]> => {
      const requestPath = './data/timezones.json';
      const response = await fetch(requestPath, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      });
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson as TimeZone[];
    },
    getPSAPList: async (query: PsapQuery): Promise<Psap[]> => {
      const requestPath = appendQueryString(`${apiUrl}psaps`, query);
      const adminToken = JSON.parse(sessionStorage.adminToken);
      const response = await fetch(requestPath, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${adminToken}`,
        },
      });
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson as Psap[];
    },
    getClientList: async function (query: '') {
      const requestPath = appendQueryString(`${apiUrl}clients/list`, query);

      const response = await fetch(requestPath, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.accessToken}`,
        },
      });
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson;
    },
    putSettings: async function (query: '', clientID: any, setting: ClientSettings) {
      const requestPath = appendQueryString(`${apiUrl}clients/${clientID}/settings`, query);

      const response = await fetch(requestPath, {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.accessToken}`,
        },

        body: JSON.stringify(setting),
      });
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson;
    },
    getClientSettings: async (query: '', clientID: string): Promise<ClientSettings> => {
      const requestPath = appendQueryString(`${apiUrl}clients/${clientID}/settings`, query);
      const response = await fetch(requestPath, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.accessToken}`,
        },
      });
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson as ClientSettings;
    },
    // Site Settings
    putSiteSettings: async (settings: SiteSettings): Promise<SiteSettings> => {
      const response = await putData(`${apiUrl}admin/settings`, settings, sessionStorage.accessToken);
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson as SiteSettings;
    },
    getSiteSettings: async (): Promise<SiteSettings> => {
      const response = await getData(`${apiUrl}admin/settings`, sessionStorage.accessToken);
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson as SiteSettings;
    },
    // Users
    getAllUsers: async (query: RequestOptions): Promise<User[]> => {
      const requestPath = appendQueryString(`${apiUrl}users/all`, query);
      const response = await fetch(requestPath, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.accessToken}`,
        },
      });
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson as User[];
    },
    putUser: async (signalUserId: string, user: User) => {
      const response = await putData(`${apiUrl}users/${signalUserId}`, user, sessionStorage.accessToken);

      return response.ok;
    },
    postRequestClientUserAccess: async function (invitation: ClientUserRequest): Promise<ClientUser> {
      const requestPath = appendQueryString(`${apiUrl}users/clientusers`, '');
      const response = await fetch(requestPath, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.accessToken}`,
        },
        body: JSON.stringify(invitation),
      });
      await ensureSuccessStatusCodeOrGetErrorTextAsync(response, 'Signal Agency');

      const responseJson = await response.json();
      return responseJson;
    },
    getClientUsersForUser: async function (): Promise<ClientUser[]> {
      const requestPath = appendQueryString(`${apiUrl}users/clientusers`, '');
      const response = await fetch(requestPath, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.accessToken}`,
        },
      });
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson;
    },
    getClientUsersForClientID: async function (query: RequestOptions, clientID: string): Promise<ClientUser[]> {
      const requestPath = appendQueryString(`${apiUrl}clients/${clientID}/clientusers`, query);
      const response = await fetch(requestPath, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.accessToken}`,
        },
      });
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson;
    },
    putClientUser: async function (clientUser: ClientUser, currentClientID: string) {
      const requestPath = appendQueryString(
        `${apiUrl}clients/${currentClientID}/clientusers/${clientUser.clientUserID}`,
        ''
      );

      const response = await fetch(requestPath, {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.accessToken}`,
        },
        body: JSON.stringify(clientUser),
      });
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson;
    },

    postSaveClient: async function (client: Client) {
      const requestPath = appendQueryString(`${apiUrl}clients`, '');

      const response = await fetch(requestPath, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.accessToken}`,
        },
        body: JSON.stringify(client),
      });
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson;
    },

    postSaveClientSettings: async function (client: Client, settings: ClientSettings) {
      const requestPath = appendQueryString(`${apiUrl}clients/${client.clientID}/settings`, '');

      const response = await fetch(requestPath, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.accessToken}`,
        },
        body: JSON.stringify(settings),
      });
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson;
    },

    activateClientUserInvitation: async function (invitation: ClientUserRequest) {
      const requestPath = appendQueryString(`${apiUrl}users/clientuserinvitations/activate`, '');
      const response = await fetch(requestPath, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.accessToken}`,
        },
        body: JSON.stringify(invitation),
      });
      await ensureSuccessStatusCodeOrGetErrorTextAsync(response, 'Invitation to Signal Agency');

      const responseJson = await response.json();
      return responseJson;
    },

    getClientUserInvitationsForClientID: async function (
      query: RequestOptions,
      clientID: string
    ): Promise<ClientUserInvitation[]> {
      const requestPath = appendQueryString(`${apiUrl}clients/${clientID}/clientuserinvitations`, query);

      const response = await fetch(requestPath, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.accessToken}`,
        },
      });
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson;
    },
    changeResponderStatus: async function (query: '', SignalUserID: any, StatusTypeID: any) {
      const incident = JSON.parse(sessionStorage.incident);
      const clientID = JSON.parse(sessionStorage.clientAlias);
      const requestPath = appendQueryString(
        `${apiUrl}clients/${clientID.clientID}/dispatchincidents/${incident}/DispatchResponders`,
        query
      );
      const response = await fetch(requestPath, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.accessToken}`,
        },
        body: JSON.stringify({ SignalUserID: SignalUserID, StatusTypeID }),
      });
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson;
    },
    postClientUserInvitation: async function (clientUserInvitation: any) {
      const requestPath = appendQueryString(
        `${apiUrl}clients/${clientUserInvitation.clientID}/clientuserinvitations`,
        ''
      );

      const response = await fetch(requestPath, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.accessToken}`,
        },
        body: JSON.stringify(clientUserInvitation),
      });
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson;
    },
    resendClientUserInvitation: async function (clientUserInvitation: any) {
      const response = await postData(
        `${apiUrl}users/clientuserinvitations/resend`,
        clientUserInvitation,
        sessionStorage.accessToken
      );
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson;
    },
    postClientUserInvitationList: async function (clientID: any, clientUserInvitationList: any) {
      const requestPath = appendQueryString(`${apiUrl}clients/${clientID}/clientuserinvitations/bulk`, '');

      const response = await fetch(requestPath, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.accessToken}`,
        },
        body: JSON.stringify(clientUserInvitationList),
      });
      await ensureSuccessStatusCodeAsync(response);

      // The Bulk import returns a string, not JSON
      const responseString = await response;
      return responseString;
    },
    putClientUserInvitation: async function (clientUserInvitation: ClientUserInvitation) {
      const response = await fetch(
        `${apiUrl}clients/${clientUserInvitation.clientID}/clientuserinvitations/${clientUserInvitation.clientUserInvitationID}`,
        {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.accessToken}`,
          },
          body: JSON.stringify(clientUserInvitation),
        }
      );
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson;
    },
    // Unit Resources
    getClientResourceUnitList: async function (query: RequestOptions, clientID: string): Promise<string[]> {
      const requestPath = appendQueryString(`${apiUrl}clients/${clientID}/resources/units`, query);
      const response = await getData(requestPath, sessionStorage.accessToken);
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson;
    },
    // Resources
    getResourceAgeUnits: async function () {
      const response = await getData(`${apiUrl}resources/ageunits`, sessionStorage.accessToken);
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson as SignalResource[];
    },
    getResourceGenders: async function () {
      const response = await getData(`${apiUrl}resources/genders`, sessionStorage.accessToken);
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson as SignalResource[];
    },
    // Call Triage Resources
    getCallTriageIncidentTypes: async function () {
      const response = await getData(`${apiUrl}incidents/types`, sessionStorage.accessToken);
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson as CallTriageIncidentType[];
    },
    getCallTriageIncidentSubtypes: async function () {
      const response = await getData(`${apiUrl}incidents/subtypes`, sessionStorage.accessToken);
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson as CallTriageIncidentSubtype[];
    },
    getCallTriageIncidentStatuses: async function () {
      const response = await getData(`${apiUrl}incidents/statuses`, sessionStorage.accessToken);
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson as CallTriageIncidentStatus[];
    },
    getCallTriageIncidentDispositions: async function () {
      const response = await getData(`${apiUrl}incidents/dispositions`, sessionStorage.accessToken);
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson as CallTriageIncidentDisposition[];
    },
    getCallTriageCommonPlaces: async function (clientId: string) {
      const response = await getData(`${apiUrl}clients/${clientId}/resources/commonplaces`, sessionStorage.accessToken);
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson as CallTriageCommonPlace[];
    },
    getCallTriageAgencies: async function (clientId: string) {
      const response = await getData(`${apiUrl}clients/${clientId}/resources/agencies`, sessionStorage.accessToken);
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson as CallTriageAgency[];
    },
    // Events
    getAllEvents: async function () {
      const response = await getData(`${apiUrl}events`, sessionStorage.accessToken);
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson as SignalEvent[];
    },
  });
  return client;
}

export type ApiClient = ReturnType<typeof createApiClient>;
