import api from '../../common/api';
import { CallTriageAuditLog, CallTriageComment, CallTriageIncident } from '../../components/callTriage/types';
import { getData, postData, putData } from '../../models/Helpers/FetchHelper';
import { ApiResponseError } from './ApiResponseError';
import { ensureSuccessStatusCodeAsync, getApiUrlOrDefault } from './shared';

export function createIncidentApiClient(options?: { baseUrl?: string; apiUrl?: string }) {
  const baseUrl = `${api.baseUrl}/`;
  const apiUrl = getApiUrlOrDefault(options?.apiUrl, baseUrl);
  const client = Object.freeze({
    // Incidents
    getAllIncidents: async function (clientID: string) {
      const response = await getData(`${apiUrl}clients/${clientID}/incidents`, sessionStorage.accessToken);
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson;
    },
    getIncident: async function (clientID: string, incidentID: string) {
      const response = await getData(
        `${apiUrl}clients/${clientID}/incidents/${incidentID}`,
        sessionStorage.accessToken
      );
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = await response.json();
      return responseJson as CallTriageIncident;
    },
    postIncident: async function (clientID: string, incident: CallTriageIncident) {
      const response = await postData(`${apiUrl}clients/${clientID}/incidents`, incident, sessionStorage.accessToken);
      if (!response.ok) {
        const error = await response.text();
        throw new ApiResponseError(error, response.status);
      }

      const responseJson = await response.json();
      return responseJson as CallTriageIncident;
    },
    putIncident: async function (clientID: string, incident: CallTriageIncident) {
      const response = await putData(`${apiUrl}clients/${clientID}/incidents`, incident, sessionStorage.accessToken);
      if (!response.ok) {
        const error = await response.text();
        throw new ApiResponseError(error, response.status);
      }

      const responseJson = await response.json();
      return responseJson as CallTriageIncident;
    },
    // Incident Notes
    postIncidentNote: async function (clientID: string, incidentID: string, comment: CallTriageComment) {
      const response = await postData(
        `${apiUrl}clients/${clientID}/incidents/${incidentID}/comments`,
        comment,
        sessionStorage.accessToken
      );
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = (await response.json()) as CallTriageComment;
      return responseJson;
    },
    getIncidentNotes: async function () {
      return;
    },
    // Audit Log
    getAuditLog: async function (clientID: string, incidentID: string) {
      const response = await getData(
        `${apiUrl}clients/${clientID}/incidents/${incidentID}/auditlogs`,
        sessionStorage.accessToken
      );
      await ensureSuccessStatusCodeAsync(response);

      const responseJson = (await response.json()) as CallTriageAuditLog[];
      return responseJson;
    },
  });
  return client;
}

export type IncidentApiClient = ReturnType<typeof createIncidentApiClient>;
