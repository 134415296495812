import { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { Outlet } from 'react-router-dom';
import currentUser from '../../models/CurrentUser';

const RequireSignalAdminRoute = () => {
  const [userIsSignalAdmin, setUserSignalAdmin] = useState(false);

  useEffect(() => {
    if (currentUser.isSignalAdmin() !== userIsSignalAdmin) {
      setUserSignalAdmin(currentUser.isSignalAdmin());
    }
  }, [userIsSignalAdmin, setUserSignalAdmin]);

  if (userIsSignalAdmin) {
    return <Outlet />;
  }

  return <Alert variant="warning">Access Denied</Alert>;
};

export default RequireSignalAdminRoute;
