import api from '../../common/api';
import { postData } from '../../models/Helpers/FetchHelper';
import { getApiUrlOrDefault } from './shared';

export function createAdminApiClient(options?: { baseUrl?: string; apiUrl?: string }) {
  const baseUrl = `${api.baseUrl}/`;
  const apiUrl = getApiUrlOrDefault(options?.apiUrl, baseUrl);
  const client = Object.freeze({
    bulkReset: async function (request: { userIds: string[] }) {
      const activateAccountResponse = await postData(`${apiUrl}admin/bulk-reset`, request, sessionStorage.accessToken);

      var response = (await activateAccountResponse.json()) as {
        count: number;
        errorMessages: string[];
      };
      return response;
    },
  });
  return client;
}

export type AdminApiClient = ReturnType<typeof createAdminApiClient>;
