import moment from 'moment';
import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import Table from 'react-bootstrap/Table';
import { ClientUser } from '../../models/ClientUser';
import { getPaginationItems } from '../../models/Helpers/PaginationHelper';
import ClientUserCard from '../clientUserCard/clientUserCard';

interface ManageUsersTabProps {
  activeClientUserCount: number;
  pagination: {
    currentPage: number;
    totalPages: number;
    onPageClick: (page: number) => void;
    onNextClick: (event: React.MouseEvent) => void;
    onPrevClick: (event: React.MouseEvent) => void;
  };
  visibleClientUsers: ClientUser[];
  displayPaginationSummary: () => string;
  handleUserRoleChange: (e: React.MouseEvent<Element, MouseEvent>, clientUser: ClientUser) => void;
  handleUserStateChange: (
    e: React.MouseEvent<Element, MouseEvent>,
    clientUser: ClientUser,
    overrideUserStateId?: number
  ) => void;
  userLimit: number;
}

const ManageUsersTab = ({
  activeClientUserCount,
  pagination,
  displayPaginationSummary,
  handleUserRoleChange,
  handleUserStateChange,
  visibleClientUsers,
  userLimit,
}: ManageUsersTabProps) => {
  const user = JSON.parse(sessionStorage.user);

  return (
    <>
      {visibleClientUsers.length > 0 ? (
        <div className="wrapper white-bg">
          <span className="text-bold">
            Active Users: {activeClientUserCount} of {userLimit}
          </span>
          <span className="text-normal">
            Click the column item to Activate or Inactivate a user, or change a user from Admin to User. Approve or Deny
            users that have requested access to your feed.
          </span>
          <Table hover className="list-table table table-striped" aria-label="Active Users">
            <thead>
              <tr>
                <th>Status</th>
                <th>User Role</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {visibleClientUsers.map((data) => {
                return (
                  <ClientUserCard
                    key={data.clientUserID}
                    mySignalUserID={user.signalUserID}
                    firstname={data.firstname}
                    lastname={data.lastname}
                    emailAddress={data.userEmailAddress}
                    signalUserID={data.signalUserID}
                    clientUserState={data.clientUserState}
                    clientUserRole={data.clientUserRole}
                    modifiedOnUtc={moment(data.modifiedOnUtc).format('HH:mm')}
                    canApprove={activeClientUserCount < userLimit}
                    handleUserStateClick={(e) => {
                      handleUserStateChange(e, data);
                    }}
                    handleUserStateApproveClick={(e) => {
                      handleUserStateChange(e, data, 2);
                    }}
                    handleUserStateDenyClick={(e) => {
                      handleUserStateChange(e, data, 3);
                    }}
                    handleUserRoleClick={(e) => {
                      handleUserRoleChange(e, data);
                    }}
                  />
                );
              })}
            </tbody>
          </Table>
          <span>{displayPaginationSummary()}</span>
          {pagination.totalPages > 1 ? (
            <Pagination>
              <Pagination.Prev onClick={pagination.onPrevClick} />
              {getPaginationItems(pagination.currentPage, pagination.totalPages, pagination.onPageClick)}
              <Pagination.Next onClick={pagination.onNextClick} />
            </Pagination>
          ) : null}
        </div>
      ) : (
        <div className="wrapper white-bg">
          <span>No existing users found.</span>
        </div>
      )}
    </>
  );
};

export default ManageUsersTab;
