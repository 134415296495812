import React from 'react';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import '../../listTable.css';
import { SiteSettingsUser } from './siteSettings';

export default function UserCard(props: {
  key: number;
  user: SiteSettingsUser;
  mySignalUserID: string;
  handleUserStateClick: (event: React.MouseEvent) => void;
  handleUserRoleClick: (event: React.MouseEvent) => void;
  handleUserSelect: (event: React.MouseEvent) => void;
}): JSX.Element {
  const IsSelf = (props: any) => {
    return props.signalUserID === props.mySignalUserID;
  };

  const roleDisplay = (user: SiteSettingsUser) => {
    return user.adminFlag ? 'Admin' : 'User';
  };

  const stateDisplay = (user: SiteSettingsUser) => {
    return user.activeFlag ? 'Active' : 'Disabled';
  };

  return (
    <tr className={IsSelf(props) ? 'list-table-row-disabled list-table-row' : 'list-table-row'}>
      <td className="list-table-col list-table-col-text text-center">
        <Form.Check checked={props.user.selected} onClick={props.handleUserSelect} />
      </td>
      <td className="list-table-col list-table-col-text">
        {IsSelf(props) ? (
          <span>{stateDisplay(props.user)}</span>
        ) : (
          <Badge
            pill
            className="clickable"
            title="Click to change State to Active"
            bg="primary"
            onClick={props.handleUserStateClick}
          >
            {stateDisplay(props.user)}
          </Badge>
        )}
      </td>
      <td className="list-table-col list-table-col-text">
        {IsSelf(props) ? (
          <span>{roleDisplay(props.user)}</span>
        ) : (
          <Badge
            pill
            className="clickable"
            title="Click to change Role to User"
            bg="primary"
            onClick={props.handleUserRoleClick}
          >
            {roleDisplay(props.user)}
          </Badge>
        )}
      </td>
      <td className="list-table-col list-table-col-text">{props.user.firstname}</td>
      <td className="list-table-col list-table-col-text">{props.user.lastname}</td>
      <td className="list-table-col list-table-col-text">
        {props.user.email}
        {IsSelf(props) && (
          <span>
            {' '}
            <Badge pill bg="success" title="This is you. You cannot modify your own Status or Role.">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-person-fill"
                viewBox="0 0 16 16"
              >
                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
              </svg>
            </Badge>
          </span>
        )}
      </td>
      {/* <td className="list-table-col list-table-col-text">
          <DropdownButton title={'Actions'}>
            <DropdownItem>Send Reset Password Email</DropdownItem>
          </DropdownButton>
        </td> */}
    </tr>
  );
}
